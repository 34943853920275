import React from 'react';
import './OwnerAssignmentModal.css';

const OwnerAssignmentModal = ({ isOpen, onClose, orgUsers, onAssign, selectedOwner, setSelectedOwner }) => {
  if (!isOpen) return null;

  const handleChange = (e) => {
    const selectedUserId = e.target.value;
    if (selectedUserId) {
      const selectedUser = orgUsers.find(user => user.id === selectedUserId);
      setSelectedOwner({
        id: selectedUser.id,
        name: `${selectedUser.fname} ${selectedUser.lname}`
      });
    } else {
      setSelectedOwner(null);
    }
  };

  return (
    <div className="owner-modal-overlay">
      <div className="owner-modal">
        <h3>Assign Owner</h3>
        <select
          value={selectedOwner ? selectedOwner.id : ''}
          onChange={handleChange}
        >
          <option value="">Select an owner</option>
          {orgUsers.map((user) => (
            <option key={user.id} value={user.id}>
              {user.fname} {user.lname}
            </option>
          ))}
       
        </select>
        <div className="modal-buttons">
          <button onClick={onAssign}>Assign</button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default OwnerAssignmentModal; 