import React, { createContext, useState, useContext } from 'react';

const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
  const [isAuditor, setIsAuditor] = useState(false);
  const [clientId, setClientId] = useState('');
  const [orgId, setOrgId] = useState('');

  return (
    <GlobalStateContext.Provider value={{ isAuditor, setIsAuditor, clientId, setClientId, orgId, setOrgId }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => useContext(GlobalStateContext);