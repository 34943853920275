import React from 'react';
import './AssetManagementHeader.css';

const AssetManagementHeader = () => {
  return (
    <div className="asset-management-header">
      <h1 className="asset-management-title">Asset Management</h1>
    </div>
  );
};

export default AssetManagementHeader;