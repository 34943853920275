import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Login from './components/Login';
import SignUp from './components/SignUp';
import Dashboard from './components/Dashboard';
import AboutUs from './components/AboutUs';
import Blog from './components/Blog';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const MainLayout = () => {
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authSource, setAuthSource] = useState(null); // Track which auth source logged the user in
  const location = useLocation();
  const auth = getAuth(); // Original Firebase auth instance

  useEffect(() => {
    // Function to handle auth state changes
    const handleAuthStateChange = (user, source) => {
      console.log(`${source} auth state change detected, logging user object: `);
      console.log(user);
      
      if (user) {
        console.log(`User authenticated from ${source}:`, user);
        setUser(user); // Set user state when authenticated
        setIsLoggedIn(true); // Set authenticated state
        setAuthSource(source); // Record which source authenticated the user
      } else {
        console.log(`No user authenticated from ${source}`);
        if (!authSource || authSource === source) {
          // Only update state if this was the source that last authenticated the user
          setIsLoggedIn(false); // Set not logged in
          setUser(null); // Reset user state
        }
      }
    };

    // Listen for auth state changes from both Firebase auth instances
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (!authSource || authSource === 'original') {
        handleAuthStateChange(user, 'original');
      }
    });

    const unsubscribeauth = onAuthStateChanged(auth, (user) => {
      if (!authSource || authSource === 'SAML') {
        handleAuthStateChange(user, 'SAML');
      }
    });

    return () => {
      unsubscribeAuth(); // Cleanup original Firebase auth subscription
      unsubscribeauth(); // Cleanup SAML Firebase auth subscription
    };
  }, [auth, auth, authSource]);

  // Determine if the current route is the dashboard
  const isDashboardRoute = location.pathname === '/dashboard';

  return (
    <div className="App">
      {/* Show Header and Footer only if not on the dashboard route */}
      {!isDashboardRoute && <Header />}
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/login" element={isLoggedIn ? <Navigate to="/dashboard" /> : <Login />} />
          <Route path="/signup" element={isLoggedIn ? <Navigate to="/dashboard" /> : <SignUp />} />
          <Route path="/dashboard" element={isLoggedIn ? <Dashboard /> : <Navigate to="/login" />} />
          {/* Add other routes as needed */}
        </Routes>
      </main>
      {!isDashboardRoute && <Footer />} {/* Show Footer only if not on the dashboard route */}
    </div>
  );
};

export default MainLayout; 