import React, { useState, useEffect } from 'react';
import LeftSidebar from './LeftSidebar';
import CenterContent from './CenterContent';
import RightSidebar from './RightSidebar';
import './Dashboard.css'; 
import { useGlobalState } from '../context/GlobalStateContext';
import { getAuth, onAuthStateChanged } from 'firebase/auth'; 

const Dashboard = () => {
  const [view, setView] = useState('overview'); 
  const [selectedControl, setSelectedControl] = useState(null); 
  const [selectedClient, setSelectedClient] = useState(null); 
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showClientSelectionPopup, setShowClientSelectionPopup] = useState(false);
  const [availableClients, setAvailableClients] = useState([]);

  const { userType, orgId, clientId, setClientId } = useGlobalState();

  // Function to handle auth state changes from both original and SAML Firebase
  const handleAuthStateChange = (user, source) => {
    console.log(`${source} auth state change detected, logging user object: `); 
    console.log(user);
    setIsLoggedIn(!!user); // Set true if user is authenticated, otherwise false
  };

  useEffect(() => {
    const auth = getAuth(); // Original Firebase Auth instance

    // Listen for auth state changes from both Firebase instances
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      handleAuthStateChange(user, 'original');
    });

    const unsubscribeauth = onAuthStateChanged(auth, (user) => {
      handleAuthStateChange(user, 'SAML');
    });

    return () => {
      unsubscribeAuth(); // Cleanup original Firebase auth subscription
      unsubscribeauth(); // Cleanup SAML Firebase auth subscription
    };
  }, []);

  useEffect(() => {
    // Show the popup if selectedClient isn't set on page load
    if (!selectedClient && userType === 'auditor') {
      fetchLinkedClients();
      setShowClientSelectionPopup(true);
    }
  }, [userType, selectedClient]); 

  const baseUrl = process.env.NODE_ENV === 'production'
    ? 'https://godfirst-vayu-app-qravzvgjba-uc.a.run.app'
    : 'http://localhost:8080';

  const fetchLinkedClients = async () => {
    try {
      // Fetch the linked clients here
      const response = await fetch(`${baseUrl}/api/linked-clients`); // Adjust the API endpoint as needed
      const clients = await response.json();
      setAvailableClients(clients);
    } catch (error) {
      console.error('Error fetching linked clients:', error);
    }
  };

  const handleClientSelection = (client) => {
    setClientId(client.id); // Update the global state with the selected client ID
    setSelectedClient(client.id); // Update local state
    setShowClientSelectionPopup(false); // Close the popup
  };

  const handleTileClick = (controlType, clientName, clientId) => {
    console.log('Clicked Tile:', { controlType, clientName, clientId });
    setSelectedControl(controlType);
    if (clientId) {
      console.log('Updating selectedClient state to:', clientId);
      setSelectedClient(clientId); // Store the clientId in state
    }
    setView('controls');
  };

  useEffect(() => {
    console.log("Selected Control:", selectedControl);
    console.log("Selected Client ID:", selectedClient);
  }, [selectedControl, selectedClient]);

  const handleOpenDetailView = () => {
    console.log('handleOpenDetailView called!'); 
    setView('detail'); // Set the view to 'detail' when 'View Details' is clicked
  };

  const handleCloseDetailView = () => {
    setView('controls'); // Revert back to 'controls' or another view when closing detail view
  };  

  const handleControlsClick = async () => {
    if (clientId) {
      console.log('Updating selectedClient state to:', clientId);
      setSelectedClient(clientId); // Store the clientId in state
    } else if (orgId) {
      console.log('Updating selectedOrg state to:', orgId);
      setSelectedClient(orgId); // Store the orgId in the selectedClient state if no clientId is available
    } else {
      console.error('No clientId or orgId available to fetch controls');
      return; // Exit the function if neither clientId nor orgId is available
    }
  
    setView('controls'); // Navigate to controls view
  }; 

  return (
    <div className="dashboard">
      <LeftSidebar 
        onHomeClick={() => setView('overview')} 
        onDocumentsClick={() => setView('documents')} 
        onControlsClick={handleControlsClick} 
        onToDoListClick={() => setView('todolist')} 
        onVirtualTeamClick={() => setView('virtualteam')}
        onSettingsClick={() => setView('settings')}
        onControlLibraryClick={() => setView('controllibrary')} 
        onAccessReviewClick={() => setView('accessreview')}
        onIncidentReportClick={() => setView('incidentreport')}
        onSystemsHealthClick={() => setView('systemshealth')}
        onMobileDeviceManagementClick={() => setView('mobiledevicemanagement')}
        onEndpointDetectionAndResponseClick={() => setView('endpointdetectionandresponse')}
        onPatchingClick={() => setView('patching')}
        onAssetManagementClick={() => setView('assetmanagement')}
        onClientsClick={() => setView('clients')}
        onAuditorsClick={() => setView('auditors')}
        onVayuMissionControlClick={() => setView('vayumissioncontrol')}
        onScopingClick={() => setView('scoping')}
        onIssuesClick={() => setView('issues')}
        onRealTimeRiskProtectionClick={() => setView('live')}
      />

      <CenterContent
        currentView={view}
        selectedControl={selectedControl}
        clientId={selectedClient}
        onTileClick={handleTileClick}
        onOpenDetailView={handleOpenDetailView} 
        onCloseDetailView={handleCloseDetailView} 
        setView={setView} // Add this line to pass setView to CenterContent
      />

      <RightSidebar onIssuesClick={() => setView('issues')} />

      {showClientSelectionPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h3>Select Client Tenant</h3>
            {availableClients.length > 0 ? (
              <div className="client-selection">
                {availableClients.map((client) => (
                  <div key={client.id}>
                    <button onClick={() => handleClientSelection(client)}>
                      {client.name}
                    </button>
                  </div>
                ))}
              </div>
            ) : (
              <p>No linked clients available.</p>
            )}
            <button onClick={() => setShowClientSelectionPopup(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard; 