import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom'; // Import useLocation
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
import MainLayout from './MainLayout';
import ChatDashboardVisibilityController from './components/ChatDashboardVisibilityController';
import './App.css';
import { useGlobalState, GlobalStateProvider } from './context/GlobalStateContext'; // Correct import

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const location = useLocation(); // Hook to get current route
  const isLoginPage = location.pathname === '/login'; // Check if user is on the login page
  const isAboutPage = location.pathname === '/about'; // Check if user is on the login page
  const isBlogPage = location.pathname === '/blog'; // Check if user is on the login page
  const isHomePage = location.pathname === '/'; // Check if user is on the home page

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  // Use the `user` state to determine if the user is logged in
  const isLoggedIn = !!user;

  if (loading) {
    return <div>Loading...</div>; // You can show a loading spinner here
  }

  return (
    <>
      <MainLayout user={user} />
      {/* Control the chat widget visibility */}
      {/* Set chat visibility to false if on the login page */}
      <ChatDashboardVisibilityController isVisible={isLoggedIn && !isLoginPage && !isHomePage && !isAboutPage && !isBlogPage} />
    </>
  );
}

const MainLayoutWithGlobalState = () => (
  <GlobalStateProvider>
    <Router>
      <App />
    </Router>
  </GlobalStateProvider>
);

export default MainLayoutWithGlobalState; 