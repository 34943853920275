import React from 'react';
import './MobileDeviceManagement.css';

const MobileDeviceManagement = () => {
  return (
    <div className="mdm-container">
      
      <div className="device-inventory">
        <h2>DEVICE INVENTORY</h2>
        <p>Total Devices: 17 &nbsp;&nbsp; Devices Online: 8 &nbsp;&nbsp; iOS Devices: 10 &nbsp;&nbsp; Android Devices: 7 &nbsp;&nbsp; Total Compliance: 76%</p>
      </div>
      
      <div className="compliance-summary">
        <h2>COMPLIANCE SUMMARY</h2>
        <p>Several of your company’s devices are currently operating on outdated versions of their respective operating systems, and a number of these devices are missing critical security patches. This could potentially expose the organization to vulnerabilities, as these systems may lack the necessary updates to protect against the latest threats and ensure optimal performance.</p>
      </div>
      
      <div className="security-alerts">
        <h2>SECURITY ALERTS AND ALERTS</h2>
        <table className="alerts-table">
          <thead>
            <tr>
              <th>NAME</th>
              <th>DEVICE</th>
              <th>MALWARE DETECTION</th>
              <th>ACCESS ATTEMPTS</th>
              <th>DEVICE AT RISK?</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Joe Biden</td>
              <td>iPhone 13</td>
              <td>8 Counts</td>
              <td>23 Counts</td>
              <td className="risk-status risk">At Risk</td>
            </tr>
            <tr>
              <td>Jack Ryan</td>
              <td>iPhone 12</td>
              <td>2 Counts</td>
              <td>4 Counts</td>
              <td className="risk-status secure">Secure</td>
            </tr>
            <tr>
              <td>Sam Ryan</td>
              <td>iPhone 14</td>
              <td>5 Counts</td>
              <td>6 Counts</td>
              <td className="risk-status secure">Secure</td>
            </tr>
            <tr>
              <td>Betty White</td>
              <td>iPhone 12</td>
              <td>1 Count</td>
              <td>7 Counts</td>
              <td className="risk-status secure">Secure</td>
            </tr>
            <tr>
              <td>Neil Peart</td>
              <td>iPhone 12</td>
              <td>4 Counts</td>
              <td>3 Counts</td>
              <td className="risk-status secure">Secure</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MobileDeviceManagement;