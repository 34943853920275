import React from 'react';
import './VayuMissionControlHeader.css';

const VayuMissionControlHeader = () => {
  return (
    <div className="vayu-mission-control-header">
      <h1 className="vayu-mission-control-title">Vayu Mission Control</h1>
    </div>
  );
};

export default VayuMissionControlHeader;