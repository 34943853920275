import React from 'react';
import './EndpointDetectionAndResponseHeader.css';

const EndpointDetectionAndResponseHeader = () => {
  return (
    <div className="edr-header">
      <h1 className="edr-title">Endpoint Detection and Response</h1>
    </div>
  );
};

export default EndpointDetectionAndResponseHeader;