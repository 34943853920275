// src/components/MainContent.js
import React from 'react';
import HeroSection from './HeroSection';
import StrengthsSection from './StrengthsSection';
import SolutionSection from './SolutionSection';
import './MainContent.css';

const MainContent = () => {
  return (
    <main className="main-content">
      <HeroSection />
      {/* <StrengthsSection /> */} 
      <SolutionSection />
    </main>
  );
};

export default MainContent;