import React, { useState } from 'react';
import './Issues.css';

const Issues = () => {
  const [issues, setIssues] = useState([
  {/*
    {
      id: 1,
      client: 'Resolve BIM',
      description: 'Model synchronization issue with Autodesk Construction Cloud',
      status: 'In Progress',
      mediationLogs: [
        {
          step: 'Agent initiated diagnostics for model synchronization.',
          status: 'Resolved',
          details: {
            timestamp: '2024-09-06T12:00:00Z',
            environment: 'Production - Autodesk Construction Cloud (v4.2)',
            example: 'Diagnostics run: sync integrity check, model version 12.3, expected timestamp 2024-09-06T11:00:00Z',
          }
        },
        {
          step: 'Client provided sync log files.',
          status: 'Resolved',
          details: {
            timestamp: '2024-09-06T13:00:00Z',
            environment: 'Client Local - Resolve BIM (v10.1)',
            example: 'Sync logs provided: file size 50MB, sync timestamps from 2024-09-05 to 2024-09-06',
          }
        },
        {
          step: 'Agent identified discrepancies in sync settings and recommended changes.',
          status: 'Resolved',
          details: {
            timestamp: '2024-09-06T14:00:00Z',
            environment: 'Resolve BIM - Autodesk API',
            example: `
              Discrepancy: Auto-sync frequency mismatch (expected 1h, actual 2h).
              Recommendation: Set sync frequency to "1h" and enable conflict auto-resolve.
            `,
          },
          policyChange: `
            sync:
              frequency: "1h"
              conflictResolution: "auto"
              backup: "enabled"
              maxRetries: 3
          `
        },
        {
          step: 'Client resubmitted with updated configurations.',
          status: 'Resolved',
          details: {
            timestamp: '2024-09-06T15:00:00Z',
            environment: 'Client Local - Resolve BIM (v10.2)',
            example: 'Resubmission: Sync settings updated, conflict resolution enabled, backup enabled.',
          }
        },
        {
          step: 'Agent verified successful model synchronization.',
          status: 'Resolved',
          details: {
            timestamp: '2024-09-06T16:00:00Z',
            environment: 'Autodesk Construction Cloud (v4.2)',
            example: 'Verification: Sync successful, model integrity check passed, conflict resolution log shows no errors.',
          }
        },
        {
          step: 'Awaiting auditor approval for final confirmation.',
          status: 'Pending',
          details: {
            timestamp: '2024-09-06T17:00:00Z',
            environment: 'Auditor Review - Autodesk Construction Cloud',
            example: 'Awaiting auditor review of the final sync settings for compliance with the original project specifications.',
          },
          isAuditorApproval: true
        }
      ]
    },
    {
      id: 2,
      client: 'AITX Security',
      description: 'AI robot experiencing connectivity issues in autonomous patrol',
      status: 'In Progress',
      mediationLogs: [
        {
          step: 'Agent initiated diagnostics on AI robot connectivity.',
          status: 'Resolved',
          details: {
            timestamp: '2024-09-06T12:00:00Z',
            environment: 'Production - AI Robot Control Network',
            example: 'Diagnostics on AI bot connection, network integrity check run successfully.',
          }
        },
        {
          step: 'Client provided network logs for review.',
          status: 'Resolved',
          details: {
            timestamp: '2024-09-06T13:30:00Z',
            environment: 'Client Local - Network Admin',
            example: 'Network logs provided for AI bot connection troubleshooting.',
          }
        },
        {
          step: 'Agent identified network configuration issue and provided update.',
          status: 'Resolved',
          details: {
            timestamp: '2024-09-06T14:00:00Z',
            environment: 'Client Network - Firewall and Routing Configurations',
            example: `
              Network Issue: Misconfigured firewall rules, blocking AI bot connection.
              Fix: Added rule to allow connection through secure VPN.
            `,
          },
          policyChange: `
            network:
              firewall:
                - allow: "AI_bot_connection"
              routing:
                preferredRoute: "secure_vpn"
              retryOnFailure: true
          `
        },
        {
          step: 'AI robot connectivity restored, awaiting client confirmation.',
          status: 'Pending',
          details: {
            timestamp: '2024-09-06T15:30:00Z',
            environment: 'AI Robot Control Network',
            example: 'Connection restored successfully, AI bot now online. Awaiting final confirmation from client.',
          }
        }
      ]
    },
    {
      id: 3,
      client: 'Resolve BIM',
      description: 'BIM model annotations not syncing with Procore',
      status: 'Not Started',
      mediationLogs: []
    } 
*/}
  ]);

  const [expandedIssue, setExpandedIssue] = useState(null);
  const [showPopup, setShowPopup] = useState(false); // For showing auditor approval popup
  const [issueToApprove, setIssueToApprove] = useState(null);
  const [resolvedIssues, setResolvedIssues] = useState([]); // Resolved issues tab
  const [activeTab, setActiveTab] = useState('issues'); // Tabs for Issues and Resolved
  const [showPolicyPopup, setShowPolicyPopup] = useState(false); // Popup for policy changes
  const [selectedLogDetail, setSelectedLogDetail] = useState(null); // For showing detailed log popup

  const handleResolveIssue = (issueId) => {
    const updatedIssues = issues.filter(issue => issue.id !== issueId);
    const resolvedIssue = issues.find(issue => issue.id === issueId);
    setResolvedIssues([...resolvedIssues, { ...resolvedIssue, status: 'Resolved by AI Agent' }]);
    setIssues(updatedIssues);
  };

  const startMediation = (issueId) => {
    const updatedIssues = issues.map(issue =>
      issue.id === issueId
        ? {
            ...issue,
            status: 'In Progress',
            mediationLogs: [
              { step: 'Agent initiated review of annotation syncing settings.', status: 'Resolved' },
              { step: 'Client provided project file and sync settings.', status: 'Resolved' },
              { step: 'Awaiting client response to sync annotation changes.', status: 'Pending' }
            ]
          }
        : issue
    );
    setIssues(updatedIssues);
  };

  const toggleMediationLogs = (issueId) => {
    setExpandedIssue(expandedIssue === issueId ? null : issueId);
  };

  const handleAuditorApproval = (issue) => {
    setIssueToApprove(issue);
    setShowPopup(true);
  };

  const approveAuditorReview = () => {
    handleResolveIssue(issueToApprove.id);
    setShowPopup(false);
    setIssueToApprove(null);
  };

  const showPolicy = (policy) => {
    setIssueToApprove({ ...issueToApprove, policyChange: policy });
    setShowPolicyPopup(true);  // Trigger the popup
  }; 

  const showDetailPopup = (details) => {
    setSelectedLogDetail(details);
    setShowPolicyPopup(true);  // Reusing the policy popup for detailed steps
  }; 

  return (
    <div className="issues-container">
      <h2 className="issues-header">Mediation (Issues)</h2>
      
      {/* Tabs for switching between Issues and Resolved */}
      <div className="tabs">
        <button className={activeTab === 'issues' ? 'active' : ''} onClick={() => setActiveTab('issues')}>Issues</button>
        <button className={activeTab === 'resolved' ? 'active' : ''} onClick={() => setActiveTab('resolved')}>Resolved</button>
      </div>

      {activeTab === 'issues' && (
        <table className="issues-table">
          <thead>
            <tr>
              <th>Client</th>
              <th>Issue Description</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {issues.map(issue => (
              <React.Fragment key={issue.id}>
                <tr className="clickable-row">
                  <td>{issue.client}</td>
                  <td>{issue.description}</td>
                  <td className={issue.status === 'Resolved by AI Agent' ? 'resolved' : 'unresolved'}>
                    {issue.status}
                  </td>
                  <td>
                    {issue.status === 'Not Started' && (
                      <button className="resolve-btn" onClick={() => startMediation(issue.id)}>
                        Start Mediation
                      </button>
                    )}
                    {issue.status === 'In Progress' && (
                      <button className="resolve-btn" onClick={() => handleResolveIssue(issue.id)}>
                        Resolve Issue
                      </button>
                    )}
                  </td>
                </tr>
                {/*
                <tr>
                    
                  <td colSpan="4">
        
                    <button
                      className="mediation-log-toggle"
                      onClick={() => toggleMediationLogs(issue.id)}
                    >
                      {expandedIssue === issue.id ? 'Hide Mediation Logs' : 'Show Mediation Logs'}
                    </button> 
             
                    {expandedIssue === issue.id && (
                      <div className="mediation-logs">
                        <h4>Mediation Logs (Steps)</h4>
                        <table className="mediation-logs-table">
                          <thead>
                            <tr>
                              <th>Step</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {issue.mediationLogs.map((log, index) => (
                              <tr key={index} className="clickable-row">
                                <td>
                                  {log.isAuditorApproval ? (
                                    <span className="auditor-approval-link" onClick={() => handleAuditorApproval(issue)}>
                                      {log.step}
                                    </span>
                                  ) : log.policyChange ? (
                                    <span className="policy-change-link" onClick={() => showPolicy(log.policyChange)}>
                                      {log.step}
                                    </span>
                                  ) : (
                                    log.step
                                  )}
                                </td>
                                <td className={log.status === 'Resolved' ? 'resolved' : 'pending'}>
                                  {log.status}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </td> 
                  </React.Fragment>
                </tr>*/}
              </React.Fragment>
            ))}
          </tbody>
          
        </table>
        
      )}
      AssetTrack Provisioning (Step: SSO) in progress. Please check back again soon.

      {activeTab === 'resolved' && (
        <table className="issues-table">
          <thead>
            <tr>
              <th>Client</th>
              <th>Issue Description</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {resolvedIssues.map(issue => (
              <tr key={issue.id}>
                <td>{issue.client}</td>
                <td>{issue.description}</td>
                <td className="resolved">{issue.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Popup for Auditor Approval */}
      {showPopup && issueToApprove && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h3>Auditor Approval Required</h3>
            <p>Configuration for issue: <strong>{issueToApprove.description}</strong></p>
            <pre>
              Sync Configurations:
              - Sync Frequency: 1 hour
              - Conflict Resolution: Auto-Resolve
              - Backup Enabled: Yes
            </pre>
            <button className="approve-btn" onClick={approveAuditorReview}>
              Approve and Resolve
            </button>
            <button className="close-popup" onClick={() => setShowPopup(false)}>
              Cancel
            </button>
          </div>
        </div>
      )}

      {/* Popup for Policy Change */}
      {showPolicyPopup && issueToApprove?.policyChange && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h3>Policy Change Details</h3>
            <pre>{issueToApprove.policyChange.trim().replace(/^\s+/gm, '')}</pre>
            <button className="close-popup" onClick={() => setShowPolicyPopup(false)}>
              Close
            </button>
          </div>
        </div>
      )} 

      {showPolicyPopup && selectedLogDetail && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h3>Step Details</h3>
            <p><strong>Timestamp:</strong> {selectedLogDetail.timestamp}</p>
            <p><strong>Environment:</strong> {selectedLogDetail.environment}</p>
            <pre>{selectedLogDetail.example}</pre>
            <button className="close-popup" onClick={() => setShowPolicyPopup(false)}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Issues; 