import React from 'react';
import './Billing.css';

const Billing = () => {
  return (
    <div className="billing-container">
      <h2 className="billing-title">Payment Method</h2>
      
      <form className="billing-form">
        <div className="billing-row">
          <label className="billing-label">Company Name</label>
          <input type="text" placeholder="Ex. Sesame Bagel Co." className="billing-input full-width" />
        </div>
        
        <div className="billing-row">
          <label className="billing-label">Name on Card</label>
          <input type="text" placeholder="Ex. Amy Smith" className="billing-input full-width name-input" />
        </div>

        <div className="billing-row">
          <label className="billing-label">Card Number</label>
          <input type="text" placeholder="1234 1234 1234 1234" className="billing-input" />
          <label className="billing-label">Expiration Date</label>
          <input type="text" placeholder="MM/YYYY" className="billing-input" />
          <label className="billing-label">CVC</label>
          <input type="text" placeholder="•••" className="billing-input cvc-input" />
        </div>

        <div className="billing-row">
          <label className="billing-label">Billing Address</label>
          <input type="text" placeholder="Ex. 123 Sesame Street" className="billing-input full-width" />
        </div>
        
        <div className="billing-row">
          <input type="text" placeholder="Ex. Unit 124" className="billing-input full-width" />
        </div>
        
        <div className="billing-row">
          <input type="text" placeholder="Address" className="billing-input full-width" />
        </div>

        <div className="billing-row">
          <input type="text" placeholder="City" className="billing-input" />&nbsp;
          <input type="text" placeholder="State" className="billing-input" />&nbsp;
          <input type="text" placeholder="Zip Code" className="billing-input" />
        </div>

        <div className="billing-row">
          <input type="text" placeholder="Country" className="billing-input full-width" />
        </div>

        <div className="billing-row button-row">
          <button type="submit" className="billing-button">Save information</button>
        </div>
      </form>
    </div>
  );
};

export default Billing;