// src/components/Footer.js
import React, { useState } from 'react';
import { X, Instagram, Music, Linkedin } from 'lucide-react';
import './Footer.css';
import ScheduleDemoModal from './ScheduleDemoModal';

const Footer = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen); 
   
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-left">
          <a href="/" className="footer-logo">
            <img src="/logo.svg" alt="Vayu Logo" width="96.52" />
          </a>
          <span className="footer-copyright">Vayu © 2024, All Rights Reserved.</span>
        </div>
        <ul className="footer-links">
          <li><a href="/about">About</a></li>
          <li><a href="#" onClick={() => { toggleModal(); toggleMobileMenu(); }}>Contact Us</a></li>
        </ul>
        <div className="footer-social">
          <a href="https://www.linkedin.com/company/joinvayu" target="_blank" rel="noopener noreferrer"><Linkedin size={20} /></a>
        </div>
      </div>
      <ScheduleDemoModal isOpen={isModalOpen} onClose={toggleModal} />
    </footer>
  );
};

export default Footer;