import React, { useState, useEffect } from 'react';
import './HeaderComponent.css';
import { auth } from '../firebase';

const HeaderComponent = () => {
  const [userName, setUserName] = useState('');
  const [timeOfDay, setTimeOfDay] = useState('Good day');

  const baseUrl =
    process.env.NODE_ENV === 'production'
      ? 'https://godfirst-vayu-app-qravzvgjba-uc.a.run.app'
      : 'http://localhost:8080';

  useEffect(() => {
    const fetchUserName = async () => {
      try {
        const currentUser = auth.currentUser;
        if (currentUser) {
          const response = await fetch(`${baseUrl}/api/users?email=${currentUser.email}`, {
            headers: {
              'Content-Type': 'application/json',
            },
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          const userData = data[0] || {};
          setUserName(userData.fname);
        } else {
          console.error('No user is currently signed in.');
        }
      } catch (error) {
        console.error('Failed to fetch user info:', error);
      }
    };

    fetchUserName();

    // Determine time of day
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      setTimeOfDay('Good morning');
    } else if (currentHour < 18) {
      setTimeOfDay('Good afternoon');
    } else {
      setTimeOfDay('Good evening');
    }
  }, [baseUrl]);

  return (
    <div className="header-component">
      <h1>{timeOfDay}, {userName}!</h1>
      <p>Let's tackle today’s priorities together!</p>
    </div>
  );
};

export default HeaderComponent; 