import React from 'react';
import './SystemsHealthHeader.css';

const SystemsHealthHeader = () => {
  return (
    <div className="systems-health-header">
      <h1 className="systems-health-title">Systems Health</h1>
    </div>
  );
};

export default SystemsHealthHeader;