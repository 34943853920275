import React from 'react';
import './ControlsHeader.css';

const ControlsHeader = () => {
  return (
    <div className="controls-header">
      <h1 className="controls-title">Controls</h1>
      <div className="controls-actions">
        {/* <button className="more-button">More &#9662;</button> */} 
       {/* <button className="add-control-button">Add Control</button> */}
      </div>
    </div>
  );
};

export default ControlsHeader;