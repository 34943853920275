import React from 'react';
import MainContent from './MainContent'; // Import MainContent component

const Home = () => {
  return (
    <div className="home">
      <MainContent /> {/* Include MainContent component here */}
    </div>
  );
};

export default Home;