import React from 'react';
import './ControlDetailView.css';

const ControlDetailView = ({ isOpen, onClose, controlDetails }) => {
  if (!isOpen || !controlDetails) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content control-detail-view" onClick={(e) => e.stopPropagation()}>
        <div className="detail-header">
          <div className="header-left">
            <button className="back-button" onClick={onClose}>← Back to Controls</button>
            <h2>{controlDetails.friendly_control_name || 'Control Details'}</h2>
          </div>
          <button className="close-button" onClick={onClose}>×</button>
        </div>
        <div className="detail-content">
          <div className="detail-section">
            <h3>ID</h3>
            <p>{controlDetails.control_id || 'N/A'}</p>
          </div>
          <div className="detail-section">
            <h3>Source</h3>
            <p>{controlDetails.source || 'N/A'}</p>
          </div>
          <div className="detail-section">
            <h3>Owner</h3>
            <p>{controlDetails.owner || 'Unassigned'}</p>
          </div>
          <div className="detail-section">
            <h3>Mapped Frameworks</h3>
            <p>{controlDetails.frameworks || 'N/A'}</p>
          </div>
          <div className="detail-section">
            <h3>Evidence</h3>
            <input type="file" multiple />
            <textarea placeholder="Provide additional evidence or notes here..."/>
            <div className="uploaded-files">
              <h4>Uploaded Files:</h4>
              <ul>
                {/* Render uploaded files */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ControlDetailView; 