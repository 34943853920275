import React from 'react';
import './MobileDeviceManagementHeader.css';

const MobileDeviceManagementHeader = () => {
  return (
    <div className="mdm-header">
      <h1 className="mdm-title">Mobile Device Management</h1>
    </div>
  );
};

export default MobileDeviceManagementHeader;