import React, { useState } from 'react';
import './VayuMissionControl.css';
import Provisioning from './Provisioning';

const VayuMissionControl = () => {
  const [activeTab, setActiveTab] = useState('Provisioning');

  const renderContent = () => {
    switch (activeTab) {
      case 'Provisioning':
        return <Provisioning />;
      default:
        return <Provisioning />;
    }
  };

  return (
    <div className="mission-control-container">
      <div className="mission-control-tabs">
        <button 
          className={`mission-control-tab ${activeTab === 'Provisioning' ? 'active' : ''}`} 
          onClick={() => setActiveTab('Provisioning')}
        >
          Provisioning
        </button>
      </div>
      <div className="mission-control-content">
        {renderContent()}
      </div>
    </div>
  );
};

export default VayuMissionControl; 