import React, { useState } from 'react';
import './AboutUs.css';
import { FaMoneyBillWave, FaBalanceScale, FaUsers, FaRobot, FaSpaceShuttle, FaLightbulb, FaFile, FaDatabase } from 'react-icons/fa';
import ScheduleDemoModal from './ScheduleDemoModal';
  
const AboutUs = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen); 

  return (
    <div className="about-us">
      {/* Mission Section */}
      <section className="mission">
        <h1>Our Mission</h1>
        <p>To fast-track the future of auditing: real-time audits with AI agents.</p>
      </section>

      {/* Problem Section */}
      <section className="problem">
        <h1>The Problem</h1>
        <div className="problem-cards">
          <div className="problem-card" style={{padding: '20px'}}>
            <FaMoneyBillWave size={50} className="icon" />
            <h2>Time & Money drain</h2>
            <p>
              Manual processes extend audit timelines by 30%. Firms lose up to 20% in potential revenue annually due to inefficiencies.
            </p>
          </div>
          &nbsp;&nbsp;&nbsp;
          <div className="problem-card">
            <FaBalanceScale size={50} className="icon" />
            <h2>Antiquated Sampling Processes</h2>
            <p>
              Traditional audit sampling examines only 1-5% of controls, missing 96% of fraudulent activities.
            </p>
          </div>
          &nbsp;&nbsp;&nbsp;
          <div className="problem-card">
            <FaUsers size={50} className="icon" />
            <h2>Auditor Talent Shortage</h2>
            <p>
              Audit demand grows while the talent pool shrinks, leading to fewer CPA exam takers and mass retirements.
            </p>
          </div>
        </div>
      </section>

      {/* Solution Section */}
      <section className="solution">
        <h1>The Solution</h1>
        <h2>Real-time Audits with AI Agents</h2>
        <p>
          Our AI agents manage up to 95% of audit tasks, cutting 30% of manual hours and preventing 20% revenue loss. With 99.99% accuracy, they streamline processes and help firms scale despite the talent shortage.
        </p>
        <div className="ai-agents">
          <div className="agent">
            <FaRobot size={80} className="bot-icon" />
            <h3>CAM</h3>
            <p>Audit Oversight & Ongoing Monitoring Agent</p>
          </div>
          &nbsp;&nbsp;&nbsp;
          <div className="agent">
            <FaRobot size={80} className="bot-icon" />
            <h3>RIYA</h3>
            <p>Risk Assessment Agent</p>
          </div>
          &nbsp;&nbsp;&nbsp;
          <div className="agent">
            <FaRobot size={80} className="bot-icon" />
            <h3>PAUL</h3>
            <p>Audit Planning & Design Effectiveness Agent</p>
          </div>
          &nbsp;&nbsp;&nbsp;
          <div className="agent">
            <FaRobot size={80} className="bot-icon" />
            <h3>EVA</h3>
            <p>Evidence Collection Agent</p>
          </div>
          &nbsp;&nbsp;&nbsp;
          <div className="agent">
            <FaRobot size={80} className="bot-icon" />
            <h3>GEN</h3>
            <p>Report Generation Agent</p>
          </div>
        </div>
      </section>

      {/* Detailed Solution Section */}
      <section className="detailed-solution">
        <h2>Advanced Analytics & Automation Across the Audit Lifecycle</h2>
        <div className="audit-lifecycle">
          <div className="lifecycle-step">
            <h3>Risk Assessment</h3>
            <p>Establish and validate the audit entity's risk universe.</p>
          </div>
          &nbsp;&nbsp;&nbsp;
          <div className="lifecycle-step">
            <h3>Audit Planning</h3>
            <p>Communicate the audit intention and scope. Develop a deep process understanding.</p>
          </div>
          &nbsp;&nbsp;&nbsp;
          <div className="lifecycle-step">
            <h3>Design Effectiveness Assessment</h3>
            <p>Perform detailed audit planning to evaluate control design.</p>
          </div>
          &nbsp;&nbsp;&nbsp;
          <div className="lifecycle-step">
            <h3>Fieldwork / Evidence Collection</h3>
            <p>Collect and verify evidence of control implementation and effectiveness.</p>
          </div>
          &nbsp;&nbsp;&nbsp;
          <div className="lifecycle-step">
            <h3>Reporting</h3>
            <p>Produce a comprehensive audit report overview and review issues identified during the audit.</p>
          </div>
          &nbsp;&nbsp;&nbsp;
          <div className="lifecycle-step">
            <h3>Vulnerability Tracking & Remediation</h3>
            <p>Monitor risks in real-time and track resolution progress.</p>
          </div>
        </div>
      </section>

      {/* Scalable Infrastructure Section */}
      <section className="scalable-infrastructure">
      <FaSpaceShuttle size={50} className="icon" />
        <h2>Scalable Infrastructure</h2>
        <p>Built for scalability, with adaptive data foundation and a standardized data model compatible with multiple frameworks.</p>
      </section>

      {/* Intelligent Evidence Collection Section */}
      <section className="evidence-collection">
      <FaLightbulb size={50} className="icon" />
        <h2>Intelligent Evidence Collection</h2>
        <p>One-click evidence collection and review, with Vayu AI Harvester Agents gathering data from diverse sources.</p>
      </section>

      {/* Automated Report Generation Section */}
      <section className="report-generation">
      <FaFile size={50} className="icon" />
        <h2>Automated Report Generation</h2>
        <p>Real-time data aggregation and dynamic pipelines for data processing, producing reports like SOC 2 Type 1.</p>
      </section>

      {/* Vulnerability Monitoring Section */}
      <section className="vulnerability-monitoring">
      <FaDatabase size={50} className="icon" />
        <h2>Ongoing Vulnerability Monitoring & Remediation</h2>
        <p>Continuous monitoring of internal and external vulnerabilities with real-time remediation to prevent data breaches.</p>
      </section>

      <br></br>
      <br></br>
      <button onClick={() => { toggleModal(); toggleMobileMenu(); }} className="btn-schedule" style={{padding: "38px"}}>Schedule a demo</button>
      <br></br>
      <br></br>
      <ScheduleDemoModal isOpen={isModalOpen} onClose={toggleModal} />
    </div>
  );
};

export default AboutUs; 