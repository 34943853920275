import React, { useState } from 'react';
import './UnlinkControlModal.css';

const UnlinkControlModal = ({ isOpen, onClose, onUnlink, unlinkReason, setUnlinkReason, setDropdownOpen }) => {
    if (!isOpen) return null;
  
    const handleCancel = () => {
      onClose();
      setDropdownOpen(null); // Close the dropdown menu when cancel is clicked
    };
  
    return (
      <div className="unlink-modal-overlay">
        <div className="unlink-modal">
          <h3>Unlink Control</h3>
          <textarea 
            value={unlinkReason} 
            onChange={(e) => setUnlinkReason(e.target.value)} 
            placeholder="Reason for unlinking"
            className="unlink-textarea"
          />
          <div className="modal-buttons">
            <button onClick={onUnlink}>Unlink</button>
            <button onClick={handleCancel}>Cancel</button> {/* Updated to use handleCancel */}
          </div>
        </div>
      </div>
    );
  }; 

export default UnlinkControlModal; 