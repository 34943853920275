import React from 'react';
import './SystemsHealth.css'; 

const SystemsHealth = () => {
  return (
    <div className="systems-health-container">
      
      <div className="accordion-section">
        {['Threat Landscape Overview', 'Incident Response Status', 'Security Alerts and Notifications', 'Network and Endpoint Monitoring', 'Threat Intelligence Integration', 'Incident Triage and Prioritization', 'Security Posture Summary', 'Response and Mitigation Actions', 'User and Access Monitoring', 'Compliance and Reporting', 'Historical Data and Trends', 'Collaboration and Communication'].map((title, index) => (
          <div className="accordion" key={index}>
            <div className="accordion-header">{title}</div>
          </div>
        ))}
      </div>

      <div className="os-distribution">
        <h2>Operating System Distribution</h2>
        <div className="os-stats">
          <div className="os-stat">
            <span className="os-color windows"></span>
            <span className="os-name">Windows</span>
            <span className="os-value">28.1k</span>
          </div>
          <div className="os-stat">
            <span className="os-color linux"></span>
            <span className="os-name">Linux</span>
            <span className="os-value">11.6k</span>
          </div>
          <div className="os-stat">
            <span className="os-color mac"></span>
            <span className="os-name">Mac</span>
            <span className="os-value">5.8k</span>
          </div>
          <div className="os-stat">
            <span className="os-color virtualization"></span>
            <span className="os-name">Virtualization</span>
            <span className="os-value">420</span>
          </div>
          <div className="os-stat">
            <span className="os-color networking"></span>
            <span className="os-name">Networking</span>
            <span className="os-value">345</span>
          </div>
        </div>
        <div className="os-chart">
          <span className="os-total">47.2k Total</span>
        </div>
      </div>

      <div className="endpoint-hardware">
        <div className="hardware-section">
          <div className="hardware-header">
            <h3>Top Endpoint Hardware</h3>
            <a href="#" className="view-all">View All</a>
          </div>
          <table className="hardware-table">
            <thead>
              <tr>
                <th>Product</th>
                <th>Model</th>
                <th>Assets</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Lenovo ThinkPad T480</td>
                <td>5</td>
                <td>14.9k</td>
              </tr>
              <tr>
                <td>Apple MacBook Pro</td>
                <td>9</td>
                <td>9.1k</td>
              </tr>
              <tr>
                <td>Lenovo ThinkPad T640</td>
                <td>4</td>
                <td>3.9k</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="total-assets-section">
          <div className="total-assets-header">
            <h3>Total Assets</h3>
            <a href="#" className="view-all">View All</a>
          </div>
          <table className="total-assets-table">
            <thead>
              <tr>
                <th>Country</th>
                <th>Count</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>United States</td>
                <td>14.9k</td>
              </tr>
              <tr>
                <td>India</td>
                <td>9.9k</td>
              </tr>
              <tr>
                <td>United Kingdom</td>
                <td>4.9k</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SystemsHealth;