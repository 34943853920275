import React, { useState, useEffect, useCallback } from 'react';
import { X } from 'lucide-react';
import './ScheduleDemoModal.css';

const ScheduleDemoModal = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    jobTitle: '',
    businessEmail: '',
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  // Determine the base URL based on the environment
  const baseUrl =
    process.env.NODE_ENV === 'production'
      ? 'https://godfirst-vayu-app-qravzvgjba-uc.a.run.app'  // Replace with your production URL
      : 'http://localhost:8080';

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${baseUrl}/api/submit-demo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log('Form submitted successfully');
        setIsSubmitted(true);
        setTimeout(() => {
          onClose();
          setIsSubmitted(false);
        }, 3000); // Close the modal after 3 seconds
      } else {
        console.error('Form submission failed');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const handleOutsideClick = useCallback((e) => {
    if (e.target.classList.contains('modal-overlay-schedule-demo')) {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen, handleOutsideClick]);

  useEffect(() => {
    if (!isOpen) {
      setFormData({
        firstName: '',
        lastName: '',
        companyName: '',
        jobTitle: '',
        businessEmail: '',
      });
      setIsSubmitted(false);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay-schedule-demo">
      <div className="modal-content-schedule-demo">
        <div className="modal-header">
          <div className="modal-title">
            <img src="/plus.png" alt="Logo" className="modal-logo" />
            <h2>Schedule a demo</h2>
          </div>
          <button onClick={onClose} className="close-button">
            <X size={24} />
          </button>
        </div>
        <div className="modal-body">
          {isSubmitted ? (
            <div className="success-message">
              <h3>Thank you!</h3>
              <p>Our team will be in touch with you shortly.</p>
            </div>
          ) : (
            <>
              <p className="modal-description">Fill in your information to book a demo with our team.</p>
              <form onSubmit={handleSubmit} className="formstyle">
                <div className="form-row">
                  <input
                    type="text"
                    name="firstName"
                    placeholder="First name*"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                  />
                  <input
                    type="text"
                    name="lastName"
                    placeholder="Last name*"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-row">
                  <input
                    type="text"
                    name="companyName"
                    placeholder="Company Name*"
                    value={formData.companyName}
                    onChange={handleChange}
                    required
                  />
                  <input
                    type="text"
                    name="jobTitle"
                    placeholder="Job Title*"
                    value={formData.jobTitle}
                    onChange={handleChange}
                    required
                  />
                </div>
                <input
                  type="email"
                  name="businessEmail"
                  placeholder="Business Email*"
                  value={formData.businessEmail}
                  onChange={handleChange}
                  required
                  className="full-width"
                />
                <button type="submit" className="submit-button">
                  Submit
                </button>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ScheduleDemoModal;