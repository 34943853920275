import React, { useState } from 'react';
import './Settings.css';
import MyAccount from './MyAccount';
import UserPermissions from './UserPermissions';
import Integrations from './Integrations';
import Preferences from './Preferences';
import Notifications from './Notifications';
import Billing from './Billing';

const Settings = () => {
  const [activeTab, setActiveTab] = useState('MyAccount');

  const renderContent = () => {
    switch (activeTab) {
      case 'MyAccount':
        return <MyAccount />;
      case 'UserPermissions':
        return <UserPermissions />;
      case 'Integrations':
        return <Integrations />;
      case 'Preferences':
        return <Preferences />;
      case 'Notifications':
        return <Notifications />;
      case 'Billing':
        return <Billing />;
      default:
        return <MyAccount />;
    }
  };

  return (
    <div className="settings-container">
      <div className="settings-tabs">
        <button 
          className={`settings-tab ${activeTab === 'MyAccount' ? 'active' : ''}`} 
          onClick={() => setActiveTab('MyAccount')}
        >
          My Account
        </button>
        <button 
          className={`settings-tab ${activeTab === 'UserPermissions' ? 'active' : ''}`} 
          onClick={() => setActiveTab('UserPermissions')}
        >
          User Permissions
        </button>
        <button 
          className={`settings-tab ${activeTab === 'Integrations' ? 'active' : ''}`} 
          onClick={() => setActiveTab('Integrations')}
        >
          Integrations
        </button>
        <button 
          className={`settings-tab ${activeTab === 'Preferences' ? 'active' : ''}`} 
          onClick={() => setActiveTab('Preferences')}
        >
          Preferences
        </button>
        <button 
          className={`settings-tab ${activeTab === 'Notifications' ? 'active' : ''}`} 
          onClick={() => setActiveTab('Notifications')}
        >
          Notifications
        </button>
        <button 
          className={`settings-tab ${activeTab === 'Billing' ? 'active' : ''}`} 
          onClick={() => setActiveTab('Billing')}
        >
          Billing
        </button>
      </div>
      <div className="settings-content">
        {renderContent()}
      </div>
    </div>
  );
};

export default Settings;