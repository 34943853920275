import React from 'react';
import './VirtualTeamHeader.css';

const VirtualTeamHeader = () => {
  return (
    <div className="virtual-team-header">
      <h1 className="virtual-team-title">Virtual Team</h1>
    </div>
  );
};

export default VirtualTeamHeader;