// Login.js

import React, { useState, useEffect } from 'react';
import { getAuth, signInWithEmailAndPassword, signInWithPopup, getRedirectResult, createUserWithEmailAndPassword, SAMLAuthProvider } from 'firebase/auth'; 
import { Link, useNavigate } from 'react-router-dom'; // Added useNavigate to enable navigation
import { FaMicrosoft } from 'react-icons/fa'; 
import './Login.css'; // Ensure this path is correct based on your project structure 

const Login = () => {
  const navigate = useNavigate(); // Hook to navigate between pages
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailForSAML, setEmailForSAML] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false); // State to control popup visibility
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // State to handle loading during login
  const [samlResult, setSamlResult] = useState(null); // State to store the result payload 

  const auth = getAuth(); // Use the original auth instance for email/password login

  const testUsers = [
    {
      label: 'AssetTrack Admin',
      email: process.env.REACT_APP_TEST_USER_ASSETTRACK_EMAIL,
      password: process.env.REACT_APP_TEST_USER_ASSETTRACK_PASSWORD,
    },
    {
      label: 'BD Emerson Admin',
      email: process.env.REACT_APP_TEST_USER_BD_EMERSON_EMAIL,
      password: process.env.REACT_APP_TEST_USER_BD_EMERSON_PASSWORD,
    },
    {
      label: 'Vayu Admin',
      email: process.env.REACT_APP_TEST_USER_VAYU_EMAIL,
      password: process.env.REACT_APP_TEST_USER_VAYU_PASSWORD,
    },
    {
      label: 'Auditor',
      email: process.env.REACT_APP_TEST_USER_AUDITOR_EMAIL,
      password: process.env.REACT_APP_TEST_USER_AUDITOR_PASSWORD,
    },
  ];

  // SAML domain configurations
  const samlProviders = {
    'bdemerson.com': 'saml.bd-emerson',
    'vayu244.onmicrosoft.com': 'saml.vayu-entra-test',
  };

  // Handle Email/Password login (Using the original Firebase app)
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log('Login attempt for email:', email);

    try {
      const cleanEmail = email.trim();
      const cleanPassword = password.trim();

      // Use Firebase email/password sign-in
      const userCredential = await signInWithEmailAndPassword(auth, cleanEmail, cleanPassword);
      const user = userCredential.user;
      console.log('User signed in successfully:', user.uid);

      setUser(user); // Update user state
      setIsLoading(false);
      navigate('/dashboard'); // Redirect to dashboard upon successful login
    } catch (error) {
      console.error('Login failed:', error);
      let errorMessage = 'Login failed. ';
      switch (error.code) {
        case 'auth/user-not-found':
          errorMessage += 'No user found with this email.';
          break;
        case 'auth/wrong-password':
          errorMessage += 'Incorrect password.';
          break;
        case 'auth/invalid-email':
          errorMessage += 'Invalid email format.';
          break;
        case 'auth/user-disabled':
          errorMessage += 'This account has been disabled.';
          break;
        default:
          errorMessage += 'Please check your credentials and try again.';
      }
      setErrorMessage(errorMessage); // Set error message in state
      setIsLoading(false);
    }
  };

  // Handle SAML Sign-in (Using the SAML-specific Firebase app)
  // Handle SAML Sign-in using Popup
  const handleSAMLSignIn = () => {
    const emailDomain = emailForSAML.split('@')[1];
    console.log('Email for SAML:', emailForSAML);
    console.log('Email domain:', emailDomain);

    if (samlProviders[emailDomain]) {
      console.log('SAML provider found for domain:', samlProviders[emailDomain]);

      // Use the correct SAML provider based on email domain
      const provider = new SAMLAuthProvider(samlProviders[emailDomain]); 

      // Use signInWithPopup instead of signInWithRedirect
      signInWithPopup(auth, provider)
        .then((result) => {
          console.log('SAML Popup login successful:', result);
          if (result.user) {
            setUser(result.user);
            navigate('/dashboard'); // Redirect to dashboard
          }
        })
        .catch((error) => {
          console.error('SAML login failed:', error);
          setErrorMessage('SAML sign-in failed.');
        });
    } else {
      console.error('No SAML provider found for the domain:', emailDomain);
      setErrorMessage('Your email domain is not recognized. Please contact an administrator.');
    }
  }; 

  // Fetch the redirect result when returning from SAML sign-in
  useEffect(() => {
    getRedirectResult(auth)
      .then(async (result) => {
        console.log('SAML authentication result:', result);
        if (result?.user) {
          console.log('User authenticated:', result.user);
          setUser(result.user); // Set the user in state
          
          // You can now navigate to the dashboard
          navigate('/dashboard');
        } else {
          console.log('No user found after redirect, staying on login page.');
        }
      })
      .catch((error) => {
        console.error('Error fetching redirect result:', error);
        setErrorMessage('SAML sign-in failed.');
      });
  }, [auth, navigate]); // Using SAML auth instance

  // Function to show popup for Microsoft login
  const handleMicrosoftLoginClick = () => {
    console.log('Showing popup for Microsoft login');
    setShowPopup(true); // Show the modal popup when Microsoft login button is clicked
  };

  // Function to close the popup
  const closePopup = () => {
    console.log('Closing popup');
    setShowPopup(false);
    setErrorMessage(''); // Clear any error message
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <Link to="/">
          <img src="/logo.svg" alt="Placeholder" width={250} />
        </Link>
        <h2>Welcome Back!</h2>
        <p>Let's get back to it</p>

        {/* Regular email/password login */}
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="loginfields"
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="loginfields"
            required
          />
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          <button type="submit" className="submit-btn" disabled={isLoading}>
            {isLoading ? 'Logging in...' : 'Log in'}
          </button>
        </form>

        {/* Social login buttons */}
        <div className="social-logins">
          <button className="social-btn microsoft" onClick={handleMicrosoftLoginClick}>
            <FaMicrosoft /> Log in with Microsoft
          </button>
        </div>

        {/* Display the SAML result for inspection */}
        {samlResult && (
          <div className="saml-result">
            <h3>SAML Authentication Payload</h3>
            <pre>{JSON.stringify(samlResult, null, 2)}</pre>
          </div>
        )}
      </div>
      <div className="login-image"></div>

      {/* Popup for SAML email input */}
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h3>Enter Your Email for Microsoft Login</h3>
            <input
              type="email"
              placeholder="Enter your email"
              value={emailForSAML}
              onChange={(e) => setEmailForSAML(e.target.value)}
              className="sso"
            />
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            <button className="submit-btn" onClick={handleSAMLSignIn}>
              Proceed with SAML Login
            </button>
            <button className="close-btn" onClick={closePopup}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login; 