import React, { useState, useEffect, useCallback } from 'react';
import { AiFillDelete, AiFillTags, AiFillEye } from 'react-icons/ai';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import { getStorage, ref as storageRef, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getFirestore, doc } from "firebase/firestore";
import { useGlobalState } from '../context/GlobalStateContext';
import './DocumentTable.css'; 
import AuditMaterialComponent from './AuditMaterialComponent';

const firestore = getFirestore();
const storage = getStorage();

const DocumentTable = () => {
  const { clientId, orgId, setOrgId, setClientId } = useGlobalState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [controlOptions, setControlOptions] = useState([]);
  const [frameworkOptions, setFrameworkOptions] = useState([]);
  const [badgeMetadata, setBadgeMetadata] = useState({});
  const [descriptionModalOpen, setDescriptionModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [fileToUpload, setFileToUpload] = useState(null); 
  const [categoryFilter, setCategoryFilter] = useState('All'); // New state for filter 
  const [activeTab, setActiveTab] = useState('Client Documents'); // New state for active tab  
  const [isEditMetadataModalOpen, setIsEditMetadataModalOpen] = useState(false); // New state for Edit Metadata modal  
  const [isEditFileNameModalOpen, setIsEditFileNameModalOpen] = useState(false); 
 
  setOrgId('gN9qsXhkz61fPOy1q2LQ');
  setClientId('Zjg7OBngxNp35Y4bEMSh');

  const baseUrl = process.env.NODE_ENV === 'production'
    ? 'https://godfirst-vayu-app-qravzvgjba-uc.a.run.app'
    : 'http://localhost:8080';

  const fetchUserOrgId = async (email) => {
    console.log('fetchUserOrgId called');
    try {
      const response = await fetch(`${baseUrl}/api/users?email=${email}`);
      if (response.ok) {
        const userData = await response.json();
        console.log('UserData: ');
        console.log(userData);
        return userData[0]?.orgId;
      } else {
        console.error('Failed to fetch user details');
      }
    } catch (error) {
      console.error('Error fetching user orgId:', error);
    }
    return null;
  }; 

  const handleEditFileName = (file) => {
    setSelectedFile({ ...file, friendlyName: file.friendly_name || file.file_name });
    setIsEditFileNameModalOpen(true);
  }; 

  const fetchOwnerName = async (userId, scope) => {
    try {
      const response = await fetch(`${baseUrl}/api/users/${userId}`);
      if (response.ok) {
        const userData = await response.json();
        return `${userData.fname} ${userData.lname}`;
      } else {
        console.error('Failed to fetch user details');
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
    return scope === 'client' ? 'AssetTrack' : 'BD Emerson';
  };

  const filteredFiles = uploadedFiles
  .filter(file => file.scope === (activeTab === 'Client Documents' ? 'client' : 'vayu'))  // Filter by scope
  .filter(file => {
    const query = searchQuery.toLowerCase();
    const nameMatch = (file.friendly_name?.toLowerCase() || '').includes(query) || (file.file_name?.toLowerCase() || '').includes(query);
    const tagsMatch = file.control_ids?.some(control => {
      const matchingMetadata = badgeMetadata[control];
      return matchingMetadata?.friendly_control_name?.toLowerCase().includes(query) || false;
    });
    const frameworkMatch = file.frameworks?.some(framework => framework?.toLowerCase().includes(query) || false);

    return nameMatch || tagsMatch || frameworkMatch;
  });   

  const fetchDocuments = useCallback(async () => {
    console.log('QA!');
   
    try {
      let resolvedOrgId = orgId;
      let resolvedClientId = clientId;
  
      if (!resolvedOrgId && !resolvedClientId) {
        const currentUser = getAuth().currentUser;
        if (currentUser) {
          resolvedOrgId = await fetchUserOrgId(currentUser.email);
          if (resolvedOrgId) {
            setOrgId(resolvedOrgId);
          } else {
            throw new Error('User does not belong to an organization');
          }
        } else {
          throw new Error('No user is currently signed in');
        }
      }
  
      const response = await fetch(`${baseUrl}/api/documents?orgId=${resolvedOrgId}`);
      if (response.ok) {
        const data = await response.json();
  
        const updatedFiles = await Promise.all(
          data
            .filter(file => file.scope === (activeTab === 'Client Documents' ? 'client' : 'vayu')) // Ensure files match the active tab's scope
            .map(async (file) => {
              const ownerName = await fetchOwnerName(file.owner, file.scope);
              return { ...file, ownerName };
            })
        );
  
        setUploadedFiles(updatedFiles); // Ensure files now have docId
      } else {
        console.error('Failed to fetch documents');
      }
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  }, [orgId, clientId, baseUrl, setOrgId, activeTab]);  // Ensure activeTab is part of dependencies 
   
  useEffect(() => {
    if (orgId) {
      fetchDocuments();
      fetchControlAndFrameworkOptions();
      fetchAllBadgeMetadata();
    }
  }, [orgId, activeTab, fetchDocuments]); // Include activeTab in the dependencies 

  const fetchControlAndFrameworkOptions = async () => {
    try {
      const controlResponse = await fetch(`${baseUrl}/api/controls?orgId=${orgId}`);
      const controlData = await controlResponse.json();

      const controlOptions = controlData.map(doc => ({
        control_id: doc.id,
        friendly_control_name: doc.friendly_control_name,
      })).sort((a, b) => a.friendly_control_name.localeCompare(b.friendly_control_name));

      const frameworkResponse = await fetch(`${baseUrl}/api/frameworks?orgId=${orgId}`);
      const frameworkData = await frameworkResponse.json();

      setControlOptions(controlOptions);
      setFrameworkOptions(frameworkData.map(fw => fw.framework_name || fw.id));
    } catch (error) {
      console.error('Error fetching Control and Framework options:', error);
    }
  };

  const fetchAllBadgeMetadata = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/controls`);
      const data = await response.json();
      const metadataMap = data.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});

      setBadgeMetadata(metadataMap);
    } catch (error) {
      console.error('Error fetching badge metadata:', error);
    }
  };

  const handleFileUpload = async (file) => {
    if (!file) return;
  
    const uniqueId = Date.now();  // Or generate a UUID for more randomness
    const scope = activeTab === 'Client Documents' ? 'client' : 'vayu';  // Determine scope based on active tab
    const formData = new FormData();
    formData.append('file', file);
    formData.append('friendlyName', file.name);
    formData.append('frameworkIds', JSON.stringify([]));  // Adjust as needed
    formData.append('controlIds', JSON.stringify([]));    // Adjust as needed
    formData.append('orgId', orgId);
    formData.append('docId', uniqueId);  // Ensure docId is always passed
    formData.append('scope', scope);  // Append scope to form data
  
    try {
      const response = await fetch(`${baseUrl}/api/upload-file`, {
        method: 'POST',
        body: formData,
      });
  
      const result = await response.json();
  
      if (response.ok && result.docId) {
        console.log('File uploaded successfully with docId:', result.docId);
        fetchDocuments();  // Refresh document list
        setProgress(0);
        setFileToUpload(null);  // Reset file selection
        setIsModalOpen(false);  // Close modal after upload
      } else {
        console.error('Error uploading file:', response.statusText);
      }
    } catch (error) {
      console.error('File upload error:', error);
    }
  }; 

  const handleDeleteClick = (file) => {
    setFileToDelete({
      ...file,
      scope: file.scope,  // Ensure scope is included
    });
    setIsDeleteModalOpen(true);
  }; 

  const handleSaveFileName = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/update-file-name`, {
        method: 'PUT',
        body: JSON.stringify({
          fileName: selectedFile?.file_name,
          newFileName: selectedFile?.friendlyName,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (response.ok) {
        fetchDocuments(); // Reload the documents after update
        setIsEditFileNameModalOpen(false); // Close the modal after saving
      } else {
        console.error('Error updating file name:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating file name:', error);
    }
  }; 
  const confirmDelete = async () => {
    if (!fileToDelete) return;
  
    try {
      const response = await fetch(`${baseUrl}/api/delete-file?docId=${fileToDelete.docId}&orgId=${orgId}&scope=${fileToDelete.scope}&fileName=${fileToDelete.file_name}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (response.ok) {
        setUploadedFiles((prevFiles) => prevFiles.filter((f) => f.docId !== fileToDelete.docId));
        setIsDeleteModalOpen(false);
      } else {
        console.error('Error deleting file:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  }; 
  const handleEditMetadata = (file) => {
    setSelectedFile({
      ...file,
      friendlyName: file.friendly_name || file.file_name,
      docId: file.id || file.docId,  // Ensure docId is set properly, defaulting to 'id' if 'docId' is missing
    });
    setIsEditMetadataModalOpen(true);
  }; 
   
  const handleSaveMetadata = async () => {
    if (!selectedFile?.docId) {
      console.error('No document ID available for metadata update.');
      return;
    }
  
    try {
      const response = await fetch(`${baseUrl}/api/update-metadata`, {
        method: 'PUT',
        body: JSON.stringify({
          docId: selectedFile.docId,  // Ensure docId is passed
          friendlyName: selectedFile?.friendlyName,
          controlIds: selectedFile?.control_ids,
          frameworks: selectedFile?.frameworks,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (response.ok) {
        fetchDocuments(); // Reload the documents after update
        setIsEditMetadataModalOpen(false); // Close the modal after saving
      } else {
        console.error('Error updating metadata:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating metadata:', error);
    }
  }; 

  return (
    <div className="document-table-container">
      <div className="table-header">
      <div className="table-tabs">
  {['Client Documents', 'Vayu Library', 'Audit Material'].map((tab) => (
    <button
      key={tab}
      className={activeTab === tab ? 'active-tab' : ''}
      onClick={() => setActiveTab(tab)}
    >
      {tab}
    </button>
  ))}
</div>


<div className="button-wrapper" style={{ marginLeft: 'auto' }}>
  {['Client Documents', 'Vayu Library'].includes(activeTab) && (
    <>
      {/* <button onClick={null} className="export-all-button">Export All</button> */}
      {/* &nbsp;&nbsp; */}
      <button onClick={() => setIsModalOpen(true)} className="add-document-button">Add Document</button>
    </>
  )}
</div> 

      </div> 



      
{/*
      <Autocomplete
        style={{ paddingBottom: "14px" }}
        freeSolo
        options={uploadedFiles.map(file => file.friendly_name || file.file_name)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search Files"
            variant="outlined"
            onChange={(e) => setSearchQuery(e.target.value)}
            fullWidth
            margin="normal"
          />
        )}
        inputValue={searchQuery}
        onInputChange={(event, newInputValue) => setSearchQuery(newInputValue)}
      /> 
      */} 
 
 {activeTab === 'Audit Material' && ( 
 <>
  <AuditMaterialComponent />
    <p>God is good</p> 
    </>

 )} 


{activeTab === 'Client Documents' && (
      <table className="document-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Category</th>
            <th>Owner</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredFiles.map((file, index) => (
            <tr key={index}>
            <td onClick={() => handleEditMetadata(file)}>
  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
    <span>{file.friendly_name || file.file_name}</span>
    {file.frameworks && file.frameworks.length > 0 && (
      <div style={{ display: "flex", gap: "6px" }}>
        {file.frameworks.map((framework, idx) => (
          <span
            key={idx}
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#ffffff",
              color: "#394d39",
              padding: "2px 8px",
              borderRadius: "12px",
              fontSize: "12px",
              border: "1px solid #394d39",
              fontWeight: "600",
              whiteSpace: "nowrap",
            }}
          >
            <span
              style={{
                width: "8px",
                height: "8px",
                borderRadius: "50%",
                backgroundColor: "#00bfa5",
                marginRight: "5px",
              }}
            ></span>
            {framework}
          </span>
        ))}
      </div>
    )}
  </div>
</td> 
<td
  onClick={() => handleEditMetadata(file)}
  style={{ cursor: 'pointer', minHeight: '50px' }} // Add style for better UX
>
  {file.control_ids?.length ? (
    file.control_ids?.map((control, idx) => {
      const matchingMetadata = badgeMetadata[control];
      return (
        <div style={{ padding: '3px' }} key={idx}>
          <span
            className="custom-badge"
            onClick={(e) => {
              e.stopPropagation();
              handleEditMetadata(file);
            }}
          >
            {matchingMetadata ? matchingMetadata.friendly_control_name : control}
          </span>
        </div>
      );
    })
  ) : (
    <div style={{ color: '#888' }}>Click to add categories</div> // Placeholder text for empty cells
  )}
</td> 
              <td>{file.ownerName || 'Unknown'}</td>
              <td>
  {file.status === 'Needs document' ? '' : ''}
</td> 
<td>
  <AiFillEye size={20} onClick={() => window.open(file.file_url, "_blank")} style={{ cursor: 'pointer', marginRight: '10px' }} />
  <AiFillDelete size={20} onClick={() => handleDeleteClick(file)} style={{ cursor: 'pointer', marginRight: '10px' }} />
  <AiFillTags size={20} onClick={() => handleEditMetadata(file)} style={{ cursor: 'pointer' }} />
</td> 
            </tr>
          ))}
        </tbody>
      </table>
      )} 

{activeTab === 'Vayu Library' && (
  <table className="document-table">
    <thead>
      <tr>
        <th>Name</th>
        <th>Category</th>
        <th>Owner</th>
        <th>Status</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      {filteredFiles.map((file, index) => (
        <tr key={index}>
          <td onClick={() => handleEditMetadata(file)}>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <span>{file.friendly_name || file.file_name}</span>
              {file.frameworks && file.frameworks.length > 0 && (
                <div style={{ display: "flex", gap: "6px" }}>
                  {file.frameworks.map((framework, idx) => (
                    <span
                      key={idx}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#ffffff",
                        color: "#394d39",
                        padding: "2px 8px",
                        borderRadius: "12px",
                        fontSize: "12px",
                        border: "1px solid #394d39",
                        fontWeight: "600",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <span
                        style={{
                          width: "8px",
                          height: "8px",
                          borderRadius: "50%",
                          backgroundColor: "#00bfa5",
                          marginRight: "5px",
                        }}
                      ></span>
                      {framework}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </td>
          <td onClick={() => handleEditMetadata(file)} style={{ cursor: 'pointer', minHeight: '50px' }}>
            {file.control_ids?.length ? (
              file.control_ids.map((control, idx) => {
                const matchingMetadata = badgeMetadata[control];
                return (
                  <div style={{ padding: '3px' }} key={idx}>
                    <span
                      className="custom-badge"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditMetadata(file);
                      }}
                    >
                      {matchingMetadata ? matchingMetadata.friendly_control_name : control}
                    </span>
                  </div>
                );
              })
            ) : (
              <div style={{ color: '#888' }}>Click to add categories</div> // Placeholder text for empty cells
            )}
          </td>
          <td>{file.ownerName || 'Unknown'}</td>
          <td>{file.status === 'Needs document' ? `Needs document since ${file.dateNeeded}` : ''}</td>
          <td>
            <AiFillEye size={20} onClick={() => window.open(file.file_url, "_blank")} style={{ cursor: 'pointer', marginRight: '10px' }} />
            <AiFillDelete size={20} onClick={() => handleDeleteClick(file)} style={{ cursor: 'pointer', marginRight: '10px' }} />
            <AiFillTags size={20} onClick={() => handleEditMetadata(file)} style={{ cursor: 'pointer' }} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)} 

      {/* Add Document Modal */}
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="modal-content">
          <h3>Upload Document</h3>
          <input
            type="file"
            onChange={(e) => setFileToUpload(e.target.files[0])}
            accept="*"
          />
          {fileToUpload && (
            <button onClick={() => handleFileUpload(fileToUpload)}>Upload</button>
          )}
          <button onClick={() => setIsModalOpen(false)}>Cancel</button>
        </div>
      </Modal>

      {/* Delete Confirmation Modal */}
<Modal open={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
  <div className="modal-content">
    <h3>Are you sure you want to delete this document?</h3>
    <button className="delete-confirm-button" onClick={confirmDelete}>
      Yes, Delete
    </button>
    &nbsp;
    <button className="cancel-button" onClick={() => setIsDeleteModalOpen(false)}>
      Cancel
    </button>
  </div>
</Modal> 

 
 {/* Edit Metadata Modal */}
<Modal open={isEditMetadataModalOpen} onClose={() => setIsEditMetadataModalOpen(false)}>
  <div className="modal-content">
    <h3>Edit Metadata for {selectedFile?.friendlyName}</h3>

    {/* Friendly Name Input */}
    <TextField
      label="Friendly Name"
      value={selectedFile?.friendlyName || ''}
      onChange={(e) =>
        setSelectedFile({ ...selectedFile, friendlyName: e.target.value })
      }
      fullWidth
      style={{ marginBottom: '15px' }}
    />
{/* Frameworks Autocomplete */}
<Autocomplete
  multiple
  options={frameworkOptions}
  value={selectedFile?.frameworks || []}
  disableClearable={false} // Allow the "x" to clear the field
  onChange={(event, newValue) => 
    setSelectedFile({
      ...selectedFile,
      frameworks: newValue, // Update the value with the current selected values (after removing or adding)
    })
  }
  renderInput={(params) => (
    <TextField {...params} label="Frameworks" fullWidth />
  )}
  style={{ marginBottom: '15px' }}
/>

{/* Categories (Control IDs) Autocomplete */}
<Autocomplete
  multiple
  options={controlOptions.map((option) => option.friendly_control_name)}
  value={selectedFile?.control_ids || []}
  disableClearable={false} // Allow the "x" to clear the field
  onChange={(event, newValue) => 
    setSelectedFile({
      ...selectedFile,
      control_ids: newValue, // Update the value with the current selected values (after removing or adding)
    })
  }
  renderInput={(params) => (
    <TextField {...params} label="Control Categories" fullWidth />
  )}
  style={{ marginBottom: '15px' }}
/> 

    {/* Save and Cancel Buttons */}
<div style={{ display: 'flex', justifyContent: 'left', marginTop: '15px' }}>
  <button
    onClick={handleSaveMetadata}
    style={{
      padding: '8px 16px',
      backgroundColor: '#4caf50',
      color: 'white',
      borderRadius: '4px',
      border: 'none',
      cursor: 'pointer',
    }}
  >
    Save
  </button>
  &nbsp;
  <button
    onClick={() => setIsEditMetadataModalOpen(false)}
    style={{
      padding: '8px 16px',
      backgroundColor: 'transparent',
      color: '#4caf50',
      border: '1px solid #4caf50',
      borderRadius: '4px',
      cursor: 'pointer',
    }}
  >
    Cancel
  </button>
</div> 
  </div>
</Modal>  

<Modal open={isEditFileNameModalOpen} onClose={() => setIsEditFileNameModalOpen(false)}>
  <div className="modal-content">
    <h3>Edit File Name</h3>
    <TextField
      label="File Name"
      value={selectedFile?.friendlyName || ''}
      onChange={(e) =>
        setSelectedFile({ ...selectedFile, friendlyName: e.target.value })
      }
      fullWidth
      style={{ marginBottom: '15px' }}
    />
    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px' }}>
      <button onClick={handleSaveFileName}>Save</button>
      <button onClick={() => setIsEditFileNameModalOpen(false)}>Cancel</button>
    </div>
  </div>
</Modal>
 
    </div>
  );
};

export default DocumentTable; 