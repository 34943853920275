import React from 'react';
import './MyAccount.css';

const MyAccount = () => {
  return (
    <div className="settings-container">
      <div className="settings-content">
        <h2 className="settings-title">PERSONAL INFO</h2>
        <form className="settings-form">

          <div className="settings-row">
            <label className="settings-label">Name</label>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input type="email" defaultValue="neha@joinvayu.com" className="settings-input full-width" />&nbsp;
            <input type="email" defaultValue="neha@joinvayu.com" className="settings-input full-width" />
          </div>
     
          <div className="settings-row">
            <label className="settings-label">Email Address</label>
            <input type="email" defaultValue="neha@joinvayu.com" className="settings-input full-width" />
          </div>
          <div className="settings-row">
            <label className="settings-label">Role</label>
            <select className="settings-input full-width" defaultValue="admin">
              <option value="admin">Admin</option>
              <option value="user">User</option>
            </select>
          </div>
          <div className="settings-row">
            <label className="settings-label">Timezone</label>
            <select className="settings-input full-width" defaultValue="pst">
              <option value="pst">Pacific Standard Time (PST) - UTC 08:00</option>
              <option value="est">Eastern Standard Time (EST) - UTC 05:00</option>
            </select>
          </div>
          <div className="settings-row">
            <label className="settings-label">Country</label>
            <select className="settings-input full-width" defaultValue="us">
              <option value="us">United States</option>
              <option value="ca">Canada</option>
              <option value="uk">United Kingdom</option>
            </select>
          </div>
          <div className="settings-row-button">
            <button type="submit" className="settings-button">Save Changes</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MyAccount;