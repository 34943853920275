import React, { useRef, useState, useEffect } from 'react';
import './SolutionSection.css';

const SolutionSection = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showPauseButton, setShowPauseButton] = useState(false);
  const [progress, setProgress] = useState(0); // For the timeline bar

  // Handle play/pause toggle
  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  // Update the timeline bar as the video plays
  const handleTimeUpdate = () => {
    const progress = (videoRef.current.currentTime / videoRef.current.duration) * 100;
    setProgress(progress);
  };

  // Allow user to seek video by dragging the timeline
  const handleTimelineChange = (e) => {
    const newTime = (e.target.value / 100) * videoRef.current.duration;
    videoRef.current.currentTime = newTime;
  };

  const handleMouseEnter = () => {
    if (isPlaying) {
      setShowPauseButton(true);
    }
  };

  const handleMouseLeave = () => {
    setShowPauseButton(false);
  };

  // Make sure to update the progress bar when the video is playing
  useEffect(() => {
    const video = videoRef.current;
    video.addEventListener('timeupdate', handleTimeUpdate);
    return () => {
      video.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, []);

  return (
    <section className="solution">
      <h2>OUR SOLUTION</h2>
      <div
        className="video-container"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <video ref={videoRef} className="video-content" width="100%" height="auto">
          <source src="https://firebasestorage.googleapis.com/v0/b/vayu-208fb.appspot.com/o/God_first_vayu_video.mp4?alt=media&token=8d31d8dc-4ae9-4a75-8f91-9d76e20c8c37" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* Play Button */}
        {!isPlaying && (
          <button className="btn-play" onClick={handlePlayPause}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-play-circle"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <polygon points="10 8 16 12 10 16 10 8"></polygon>
            </svg>
          </button>
        )}

        {/* Pause Button */}
        {isPlaying && showPauseButton && (
          <button className="btn-pause" onClick={handlePlayPause}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-pause-circle"
            >
              
              <rect x="10" y="8" width="4" height="8"></rect>
              <rect x="14" y="8" width="4" height="8"></rect>
            </svg>
          </button>
        )}

        {/* Timeline Bar */}
       
      </div>
    </section>
  );
};

export default SolutionSection;