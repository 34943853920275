import React from 'react';
import './ClientsHeader.css';

const ClientsHeader = () => {
  return (
    <div className="clients-header">
      <h1 className="clients-title">Clients</h1>
    </div>
  );
};

export default ClientsHeader;