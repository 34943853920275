import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Preferences.css';

const Preferences = () => {
  const [startDate, setStartDate] = useState(new Date('2024-02-24'));
  const [endDate, setEndDate] = useState(new Date('2024-08-24'));

  return (
    <div className="preferences-container">
      {/* Expected Timeline Section */}
      <div className="timeline-summary">
        <div className="timeline-summary-item">
          <p>Expected timeline</p>
          <h3>23 days</h3>
        </div>
        <div className="timeline-summary-item">
          <p>Avg. days per week</p>
          <h3>7</h3>
        </div>
      </div>

      {/* Framework Section */}
      <h2 className="preferences-title">Framework</h2>
      <div className="framework-tags">
        <span className="framework-tag">SOC 2</span>
        <span className="framework-tag">HIPAA</span>
        <span className="framework-tag selected">GDPR</span>
        <span className="framework-tag">ISO 27001:2022</span>
        <span className="framework-tag">PCI DSS - SAQ D, SP and ROC Prep</span>
        <span className="framework-tag">US Data Privacy</span>
      </div>

      {/* Timeline Section */}
      <h2 className="preferences-title">Timeline</h2>
      <div className="timeline-container">
        <div className="timeline-dates">
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            dateFormat="MMMM d, yyyy"
            className="date-picker"
            inline
          />
          <span className="timeline-separator">|</span>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            dateFormat="MMMM d, yyyy"
            className="date-picker"
            inline
          />
        </div>
      </div>

      {/* Save Changes Button */}
      <button className="save-changes-button">Save Changes</button>
    </div>
  );
};

export default Preferences;