import React, { useState } from 'react';
import './Clients.css';
import Provisioning  from './Provisioning';
import ClientControls from './ClientControls'; 
import VayuControlLibrary from './VayuControlLibrary'; 

const Clients = () => {
  const [activeTab, setActiveTab] = useState('Provisioning');

  const renderContent = () => {
    switch (activeTab) {
      case 'Provisioning':
        return <Provisioning />;
      case 'Controls':
        return <ClientControls />;
      case 'Documents':
        return '';
      default:
        // return <Provisioning />;
    }
  };

  return (
    <div className="clients-container">
      <div className="clients-tabs">
        <button 
          className={`clients-tab ${activeTab === 'Provisioning' ? 'active' : ''}`} 
          onClick={() => setActiveTab('Provisioning')}
        >
          Provisioning
        </button>
        <button 
          className={`clients-tab ${activeTab === 'Controls' ? 'active' : ''}`} 
          onClick={() => setActiveTab('Controls')}
        >
          Controls
        </button>
        <button 
          className={`clients-tab ${activeTab === 'Documents' ? 'active' : ''}`} 
          onClick={() => setActiveTab('Documents')}
        >
          Documents
        </button>
      </div>
      <div className="clients-content">
        {renderContent()}
      </div>
    </div>
  );
};

export default Clients;