import React from 'react';
import './PatchingHeader.css';

const PatchingHeader = () => {
  return (
    <div className="patching-header">
      <h1 className="patching-title">Patching</h1>
    </div>
  );
};

export default PatchingHeader;