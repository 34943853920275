import React, { useState, useEffect } from 'react';
import './ComplianceTile.css';
import { collection, query, where, onSnapshot } from 'firebase/firestore'; // Firestore methods
import { db } from '../firebase'; // Import Firestore instance

const ComplianceTile = ({ title, backgroundColor, progressColor, clientNames, clientId, onClick }) => {
  const [totalControls, setTotalControls] = useState(0);
  const [completedControls, setCompletedControls] = useState(0);
  const [progress, setProgress] = useState(0);

  // Fetch control data from Firestore based on client_id
  useEffect(() => {
    clientId = 'Zjg7OBngxNp35Y4bEMSh';
    if (clientId) {
      const clientControlsQuery = query(collection(db, 'client_controls'), where('client_id', '==', clientId));

      const unsubscribe = onSnapshot(clientControlsQuery, (snapshot) => {
        const controls = snapshot.docs.map(doc => doc.data());
        const total = controls.length;
        const completed = controls.filter(control => control.completed_approved === true).length;

        setTotalControls(total);
        setCompletedControls(completed);

        // Calculate the percentage of completed controls
        const percentage = total > 0 ? (completed / total) * 100 : 0;
        setProgress(percentage);
      });

      return () => unsubscribe(); // Cleanup the listener on unmount
    }
  }, [clientId]);

  return (
    <div
      className="compliance-tile"
      style={{ backgroundColor, cursor: onClick ? 'pointer' : 'default' }}
      onClick={onClick || (() => {})}  // Only make it clickable if onClick is provided
    >
      <div className="tile-content">
        <div className="tile-header">
          {title === "unknown" ? "Framework: Unknown" : title}
        </div>
        <div className="tile-body">
          <div className="progress-bar">
            <div className="progress" style={{ width: `${progress}%`, backgroundColor: progressColor }}></div>
          </div>
          <div className="tile-footer">
            <span>{progress.toFixed(2)}% Controls Complete</span>
            <span>{totalControls} Total</span>
          </div>
          <div className="tile-footer client-badges">
            {clientNames && clientNames.map((client, index) => (
              <span key={index} className="client-badge">Tenant: {client}</span>
            ))}
          </div>
        </div>
      </div>
      {onClick && <div className="tile-chevron">›</div>}
    </div>
  );
};

export default ComplianceTile; 