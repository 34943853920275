import React, { useState } from 'react';
import './Integrations.css';

const IntegrationItem = ({ service }) => {
  return (
    <div className="integration-item">
      <div className="integration-details">
        <img src={service.logo} alt={service.name} className="integration-logo" />
        <div className="integration-info">
          <h3 className="integration-name">
            {service.name} <span className="integration-status">● Connected</span>
          </h3>
          <p className="integration-description">{service.description}</p>
          <div className="integration-tags">
            {service.tags.map((tag, index) => (
              <span key={index} className="integration-tag">
                {tag}
              </span>
            ))}
          </div>
        </div>
      </div>
      <div className="integration-actions">
        <button className="integration-button">Configure Scope</button>
        <button className="integration-button">Manage</button>
      </div>
    </div>
  );
};

const Integrations = () => {
  const [activeTab, setActiveTab] = useState('connected');

  const services = [
    {
      name: 'AWS (Amazon Web Services)',
      logo: '/awslogo.svg', // Replace with actual logo URL
      description: 'Cloud providers • Version control systems • Vulnerability scanners',
      tags: ['SOC 2', 'CC.11', '+ 11 more'],
    },
    {
        name: 'AWS (Amazon Web Services)',
        logo: '/awslogo.svg', // Replace with actual logo URL
        description: 'Cloud providers • Version control systems • Vulnerability scanners',
        tags: ['SOC 2', 'CC.11', '+ 11 more'],
      },
      {
        name: 'AWS (Amazon Web Services)',
        logo: '/awslogo.svg', // Replace with actual logo URL
        description: 'Cloud providers • Version control systems • Vulnerability scanners',
        tags: ['SOC 2', 'CC.11', '+ 11 more'],
      },
      {
        name: 'AWS (Amazon Web Services)',
        logo: '/awslogo.svg', // Replace with actual logo URL
        description: 'Cloud providers • Version control systems • Vulnerability scanners',
        tags: ['SOC 2', 'CC.11', '+ 11 more'],
      },
    // Add more services as needed
  ];

  return (
    <div className="integrations-container">
      <div className="integrations-tabs">
        <button 
          className={`integrations-tab ${activeTab === 'connected' ? 'active' : ''}`} 
          onClick={() => setActiveTab('connected')}
        >
          Connected (4)
        </button>
        <button 
          className={`integrations-tab ${activeTab === 'available' ? 'active' : ''}`} 
          onClick={() => setActiveTab('available')}
        >
          Available (46)
        </button>
      </div>

      <div className="integrations-list">
        {services.map((service, index) => (
          <IntegrationItem key={index} service={service} />
        ))}
      </div>
    </div>
  );
};

export default Integrations;