import React, { useState } from 'react';
import './Notifications.css';

const Notifications = () => {
  const [desktopNotifications, setDesktopNotifications] = useState(true);
  const [chatNotifications, setChatNotifications] = useState(false);
  const [emailNotifications, setEmailNotifications] = useState(true);
  const [updateNotifications, setUpdateNotifications] = useState(true);

  return (
    <div className="notifications-container">
      <h2 className="notifications-title">Notifications</h2>
      
      <div className="notification-row">
        <span>Pop up notifications on desktop</span>
        <label className="switch">
          <input 
            type="checkbox" 
            checked={desktopNotifications} 
            onChange={() => setDesktopNotifications(!desktopNotifications)} 
          />
          <span className="slider round"></span>
        </label>
      </div>

      <div className="notification-row">
        <span>Turn on all chat notifications</span>
        <label className="switch">
          <input 
            type="checkbox" 
            checked={chatNotifications} 
            onChange={() => setChatNotifications(!chatNotifications)} 
          />
          <span className="slider round"></span>
        </label>
      </div>

      <div className="notification-row">
        <span>Turn on all email notifications</span>
        <label className="switch">
          <input 
            type="checkbox" 
            checked={emailNotifications} 
            onChange={() => setEmailNotifications(!emailNotifications)} 
          />
          <span className="slider round"></span>
        </label>
      </div>

      <div className="notification-row">
        <span>Turn on new update notifications</span>
        <label className="switch">
          <input 
            type="checkbox" 
            checked={updateNotifications} 
            onChange={() => setUpdateNotifications(!updateNotifications)} 
          />
          <span className="slider round"></span>
        </label>
      </div>
    </div>
  );
};

export default Notifications;