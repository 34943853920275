import React, { useState, useEffect, useRef } from 'react';
import { MessageCircle, Send, ThumbsUp, ThumbsDown } from 'lucide-react';
import './Scoping.css';

const Scoping = () => {
    const [messages, setMessages] = useState([]);
    const [currentStep, setCurrentStep] = useState(0);
    const [userInput, setUserInput] = useState('');
    const [collectedData, setCollectedData] = useState({});
    const initRef = useRef(false);
  
    const steps = [
      {
        question: "Welcome to the Vayu compliance onboarding process! I'm your AI assistant. Let's start with your contact information. What's your name?",
        type: 'text',
        key: 'name',
      },
      {
        question: "Great! Now, what's your email address?",
        type: 'text',
        key: 'email',
      },
      {
        question: "Thank you. Lastly, what's your phone number?",
        type: 'text',
        key: 'phone',
      },
      {
        question: "Which compliance frameworks are you currently interested in?",
        type: 'multiSelect',
        options: ['SOC 2', 'HIPAA', 'ISO 27001', 'GDPR', 'Other'],
        key: 'frameworks',
      },
      {
        question: "What is the name of your company?",
        type: 'text',
        key: 'company',
      },
      {
        question: "Which industry does your company primarily operate in?",
        type: 'select',
        options: ['Healthcare', 'Financial Services', 'Technology', 'Manufacturing', 'Retail', 'E-commerce', 'AI', 'Other'],
        key: 'industry',
      },
    ];

    useEffect(() => {
      if (!initRef.current) {
        addMessage('ai', steps[0].question);
        initRef.current = true;
      }
    }, []);

    const addMessage = (sender, text) => {
      setMessages(prevMessages => [...prevMessages, { sender, text }]);
    };

    const handleSendMessage = () => {
      if (userInput.trim() === '') return;

      if (userInput.toLowerCase() === 'qa') {
        addMessage('user', 'qa');
        addMessage('ai', JSON.stringify(collectedData, null, 2));
        setUserInput('');
        return;
      }

      addMessage('user', userInput);
      setCollectedData(prevData => ({
        ...prevData,
        [steps[currentStep].key]: userInput,
      }));
      setUserInput('');

      // Simulate AI response
      setTimeout(() => {
        if (currentStep < steps.length - 1) {
          setCurrentStep(prevStep => prevStep + 1);
          addMessage('ai', steps[currentStep + 1].question);
        } else {
          addMessage('ai', "Thank you for providing all the information. I'll now analyze your responses and suggest relevant compliance frameworks.");
        }
      }, 1000);
    };

    const handleOptionSelect = (option) => {
      addMessage('user', option);
      setCollectedData(prevData => ({
        ...prevData,
        [steps[currentStep].key]: steps[currentStep].type === 'multiSelect' 
          ? [...(prevData[steps[currentStep].key] || []), option]
          : option,
      }));
      
      // Simulate AI response
      setTimeout(() => {
        if (currentStep < steps.length - 1) {
          setCurrentStep(prevStep => prevStep + 1);
          addMessage('ai', steps[currentStep + 1].question);
        } else {
          addMessage('ai', "Thank you for providing all the information. I'll now analyze your responses and suggest relevant compliance frameworks.");
        }
      }, 1000);
    };

    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        handleSendMessage();
      }
    };

    return (
      <div className="scoping-container">
        <div className="chat-container">
          <div className="chat-messages">
            {messages.map((message, index) => (
              <div key={index} className={`message ${message.sender}`}>
                {message.sender === 'ai' && <MessageCircle size={24} />}
                <p>{message.text}</p>
              </div>
            ))}
          </div>
          <div className="chat-input">
            <input
              type="text"
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Type your answer or 'qa' to see collected data..."
            />
            <button onClick={handleSendMessage}><Send size={20} /></button>
          </div>
        </div>
        {(steps[currentStep].type === 'select' || steps[currentStep].type === 'multiSelect') && (
          <div className="option-buttons">
            {steps[currentStep].options.map((option, index) => (
              <button key={index} onClick={() => handleOptionSelect(option)}>{option}</button>
            ))}
          </div>
        )}
        <div className="feedback-buttons">
          <button><ThumbsUp size={20} /></button>
          <button><ThumbsDown size={20} /></button>
        </div>
      </div>
    );
};

export default Scoping; 