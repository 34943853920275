import React from 'react';
import './IncidentReportHeader.css';

const IncidentReportHeader = () => {
  return (
    <div className="incident-report-header">
      <h1 className="incident-report-title">Incident Reports</h1>
    </div>
  );
};

export default IncidentReportHeader;