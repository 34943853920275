import React, { useState, useEffect } from 'react';
import './RightSidebar.css'; 
import { format, addDays } from 'date-fns';
import { getAuth } from 'firebase/auth'; // Import Firebase Auth to get user info

const RightSidebar = ({ onIssuesClick }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [userEmailDomain, setUserEmailDomain] = useState('');

  // Firebase Authentication to get the logged-in user's email
  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user && user.email) {
      const emailDomain = user.email.split('@')[0]; // Extract the domain part of the email
      setUserEmailDomain(emailDomain);
    }
  }, []);

  // Conditional data based on the email domain
  const userInfo = userEmailDomain === 'admin' ? {
    fname: 'Firm ABC',
    lname: 'Demo View',
    role: 'Auditor',
  } : {
    fname: 'BD',
    lname: 'Emerson',
    role: 'Auditor',
  };

  const orgInfo = userEmailDomain === 'admin' ? {
    name: 'Firm ABC',
    type: 'auditor',
    logo: 'Godfirst_aitxgraphic.png',
    complianceFrameworks: ['SOC 2'],
  } : {
    name: 'BD Emerson',
    type: 'auditor',
    logo: 'Godfirst_helper.jpeg',
    complianceFrameworks: ['SOC 2'],
  };

  const linkedClients = userEmailDomain === 'admin' ? [
    {
      id: 2,
      name: 'Client A',
      logo: 'Godfirst_aitxgraphic.png', // Add logo path
      startDate: '2024-09-01',
      complianceFramework: ['SOC 2'],
      cloudPlatform: ['Azure'],
      teamMembers: [
        { uid: 1, fname: 'Kim', lname: 'Mitchell', role: 'User', email: 'kim.mitchell@amitracks.com' },
        { uid: 2, fname: 'Tom', lname: 'Watson', role: 'User', email: 'tom.watson@amitracks.com' },
        { uid: 3, fname: 'Michelle', lname: 'Van Hoorelbeke', role: 'User', email: 'michelle.van@amitracks.com' },
        { uid: 4, fname: 'Robert', lname: 'Young', role: 'User', email: 'robert.young@amitracks.com' },
        { uid: 5, fname: 'Eric', lname: 'Marlin', role: 'Admin', email: 'eric.marlin@amitracks.com' },
        { uid: 6, fname: 'Clayton', lname: 'Wood', role: 'User', email: 'clayton.wood@amitracks.com' },
        { uid: 7, fname: 'Rick', lname: 'Goodlaski', role: 'Admin', email: 'rick.goodlaski@amitracks.com' },
      ],
    },
    {
      id: 3,
      name: 'Client B',
      logo: 'Godfirst_aitxgraphic.png',
      startDate: '2024-09-01',
      complianceFramework: ['SOC 2'],
      cloudPlatform: ['Azure'],
      teamMembers: [
        { uid: 1, fname: 'Kim', lname: 'Mitchell', role: 'User', email: 'kim.mitchell@amitracks.com' },
        { uid: 2, fname: 'Tom', lname: 'Watson', role: 'User', email: 'tom.watson@amitracks.com' },
        { uid: 3, fname: 'Michelle', lname: 'Van Hoorelbeke', role: 'User', email: 'michelle.van@amitracks.com' },
        { uid: 4, fname: 'Robert', lname: 'Young', role: 'User', email: 'robert.young@amitracks.com' },
        { uid: 5, fname: 'Eric', lname: 'Marlin', role: 'Admin', email: 'eric.marlin@amitracks.com' },
        { uid: 6, fname: 'Clayton', lname: 'Wood', role: 'User', email: 'clayton.wood@amitracks.com' },
        { uid: 7, fname: 'Rick', lname: 'Goodlaski', role: 'Admin', email: 'rick.goodlaski@amitracks.com' },
      ],
    },
  ] : [
    {
      id: 2,
      name: 'AssetTrack',
      logo: 'assettracklogo.png',
      startDate: '2024-09-01',
      complianceFramework: ['SOC 2'],
      cloudPlatform: ['Azure'],
      teamMembers: [
        { uid: 1, fname: 'Kim', lname: 'Mitchell', role: 'User', email: 'kim.mitchell@amitracks.com' },
        { uid: 2, fname: 'Tom', lname: 'Watson', role: 'User', email: 'tom.watson@amitracks.com' },
        { uid: 3, fname: 'Michelle', lname: 'Van Hoorelbeke', role: 'User', email: 'michelle.van@amitracks.com' },
        { uid: 4, fname: 'Robert', lname: 'Young', role: 'User', email: 'robert.young@amitracks.com' },
        { uid: 5, fname: 'Eric', lname: 'Marlin', role: 'Admin', email: 'eric.marlin@amitracks.com' },
        { uid: 6, fname: 'Clayton', lname: 'Wood', role: 'User', email: 'clayton.wood@amitracks.com' },
        { uid: 7, fname: 'Rick', lname: 'Goodlaski', role: 'Admin', email: 'rick.goodlaski@amitracks.com' },
      ],
    },
  ];

  const handleClientClick = (client) => {
    setSelectedClient(client);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setSelectedClient(null);
  };

  return (
    <aside className="right-sidebar">
      {/* User Profile Section */}
      <div className="user-profile-card">
        <img src={orgInfo.logo} alt="Organization Logo" className="user-logo" />
        <div className="user-info">
          <h3>{userInfo.fname} {userInfo.lname}</h3>
          <p>{userInfo.role} at {orgInfo.name}</p>
        </div>
      </div>

      {/* Metrics Section */}
      <div className="metrics-section">
        <div className="metric">
          <h4>Active Clients</h4>
          <p className="large-number blue-number">{linkedClients.length}</p>
        </div>
        <div className="metric">
          <h4>Compliance Frameworks</h4>
          <p>{orgInfo.complianceFrameworks.join(', ')}</p>
        </div>
        <div className="metric">
          <h4>Current Issues</h4>
          <a onClick={onIssuesClick}>
            <p className="large-number orange-number" style={{cursor: 'pointer'}}>0</p>
          </a>
        </div>
      </div>

      {/* Linked Clients as Table View */}
      <div className="client-selector">
        <h4>Linked Clients</h4>
        <table className="client-table">
          <thead>
            <tr>
              <th>Logo</th>
              <th>Name</th>
              <th>Compliance</th>
              <th>Platform</th>
            </tr>
          </thead>
          <tbody>
            {linkedClients.map((client) => (
              <tr key={client.id} onClick={() => handleClientClick(client)} className="client-row">
                <td><img src={client.logo} alt={`${client.name} Logo`} className="client-logo" /></td>
                <td>{client.name}</td>
                <td>{client.complianceFramework.join(', ')}</td>
                <td>{client.cloudPlatform.join(', ')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Upcoming Events */}
      <div className="events-section">
        <h4>Upcoming Events</h4>
        <div className="calendar-view">
          {Array.from({ length: 6 }).map((_, index) => {
            const today = new Date();
            const currentDay = today.getDay();
            const startDate = currentDay === 0 || currentDay === 6 
              ? addDays(today, 8 - currentDay)
              : today;
            const dateToShow = addDays(startDate, index);
            const formattedDateDay = format(dateToShow, 'E');
            const formattedDateDate = format(dateToShow, 'MMM d');

            return (
              <div key={index} className="calendar-time-slot">
                <div className="time-label">{formattedDateDay}<br></br>{formattedDateDate}</div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Selected Client Popup */}
      {showPopup && selectedClient && (
        <div className="popup-overlay">
          <div className="popup-content">
            <img src={selectedClient.logo} alt={`${selectedClient.name} Logo`} className="popup-client-logo" />
            <h2>{selectedClient.name} Information</h2>

            {/* Client Data in Table */}
            <table className="client-info-table">
              <thead>
                <tr>
                  <th>Compliance</th>
                  <th>Platform</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{selectedClient.complianceFramework.join(', ')}</td>
                  <td>{selectedClient.cloudPlatform.join(', ')}</td>
                </tr>
              </tbody>
            </table>

            {/* Team Members in Table */}
            <h3>Team Members</h3>
            <table className="team-members-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Role</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {selectedClient.teamMembers.map(member => (
                  <tr key={member.uid}>
                    <td>{member.fname} {member.lname}</td>
                    <td>{member.role}</td>
                    <td>{member.email}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <button className="close-popup" onClick={closePopup}>Close</button>
          </div>
        </div>
      )}
    </aside>
  );
};

export default RightSidebar; 