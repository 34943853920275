import React from 'react';
import './SettingsHeader.css';

const SettingsHeader = () => {
  return (
    <div className="settings-header">
      <h1 className="settings-title">Settings</h1>
    </div>
  );
};

export default SettingsHeader;