import React, { useState } from 'react';
import './RelinkControlModal.css';

const RelinkControlModal = ({ isOpen, onClose, onRelink, relinkReason, setRelinkReason }) => {
  if (!isOpen) return null;

  return (
    <div className="relink-modal-overlay">
      <div className="relink-modal">
        <h3>Relink Control</h3>
        <textarea 
          value={relinkReason} 
          onChange={(e) => setRelinkReason(e.target.value)} 
          placeholder="Reason for relinking"
          className="relink-textarea"
        />
        <div className="modal-buttons">
          <button onClick={onRelink}>Relink</button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default RelinkControlModal; 