import React from 'react';
import HeaderComponent from './HeaderComponent';
import OverviewComponent from './OverviewComponent';
import TodoListComponent from './TodoListComponent';
import DocumentsHeader from './DocumentsHeader';
import DocumentTable from './DocumentTable';
import ToDoListActionArea from './ToDoListActionArea';
import VirtualTeamHeader from './VirtualTeamHeader';
import VirtualTeamChat from './VirtualTeamChat';
import SettingsHeader from './SettingsHeader';
import Settings from './Settings';
import AccessReviewHeader from './AccessReviewHeader';
import AccessReviews from './AccessReviews';
import IncidentReport from './IncidentReport';
import IncidentReportHeader from './IncidentReportHeader';
import SystemsHealthHeader from './SystemsHealthHeader';
import SystemsHealth from './SystemsHealth';
import MobileDeviceManagementHeader from './MobileDeviceManagementHeader';
import MobileDeviceManagement from './MobileDeviceManagement';
import EndpointDetectionAndResponseHeader from './EndpointDetectionAndResponseHeader';
import EndpointDetectionAndResponse from './EndpointDetectionAndResponse';
import PatchingHeader from './PatchingHeader';
import Patching from './Patching';
import AssetManagementHeader from './AssetManagementHeader';
import AssetManagement from './AssetManagement';
import Clients from './Clients';
import ClientsHeader from './ClientsHeader';
import VayuMissionControlHeader from './VayuMissionControlHeader';
import VayuMissionControl from './VayuMissionControl';
import ParentComponent from './ParentComponent';  
import ControlLibrary from './ControlLibrary'; 
import Issues from './Issues'; 
import Scoping from './Scoping';
import Live from './Live'; 
import './CenterContent.css';

const CenterContent = ({ currentView, selectedControl, onTileClick, selectedClient, onOpenDetailView, onCloseDetailView, setView }) => {
  return (
    <div className="center-content">
      
      {currentView === 'overview' && (
        <>
          <HeaderComponent />
          <OverviewComponent onTileClick={onTileClick} />
          <TodoListComponent setView={setView} currentView={currentView} /> {/* Pass setView to TodoListComponent */}
        </>
      )}
      {currentView === 'controls' && (
        <>
          <ParentComponent 
            selectedControl={selectedControl} 
            selectedClient={selectedClient} 
            onOpenDetailView={onOpenDetailView} 
          />
        </>
      )}
      {currentView === 'documents' && (
        <>
          <DocumentsHeader />
          <DocumentTable />
        </>
      )}
      {currentView === 'todolist' && (
        <>
          <ToDoListActionArea />
        </>
      )}
      {currentView === 'virtualteam' && (
        <>
          <VirtualTeamHeader />
          <VirtualTeamChat />
        </>
      )}
      {currentView === 'settings' && (
        <>
          <SettingsHeader />
          <Settings />
        </>
      )}
      {currentView === 'accessreview' && (
        <>
          <AccessReviewHeader />
          <AccessReviews />
        </>
      )}
      {currentView === 'incidentreport' && (
        <>
          <IncidentReportHeader />
          <IncidentReport />
        </>
      )}
      {currentView === 'systemshealth' && (
        <>
          <SystemsHealthHeader />
          <SystemsHealth />
        </>
      )}
      {currentView === 'mobiledevicemanagement' && (
        <>
          <MobileDeviceManagementHeader />
          <MobileDeviceManagement />
        </>
      )}
      {currentView === 'endpointdetectionandresponse' && (
        <>
          <EndpointDetectionAndResponseHeader />
          <EndpointDetectionAndResponse />
        </>
      )}
      {currentView === 'patching' && (
        <>
          <PatchingHeader />
          <Patching />
        </>
      )}
      {currentView === 'assetmanagement' && (
        <>
          <AssetManagementHeader />
          <AssetManagement />
        </>
      )}
      {currentView === 'clients' && (
        <>
          <ClientsHeader />
          <Clients />
        </>
      )}
      {currentView === 'auditors' && (
        <>
          <AssetManagementHeader />
          <AssetManagement />
        </>
      )}
      {currentView === 'vayumissioncontrol' && (
        <>
          <VayuMissionControlHeader />
          <VayuMissionControl />
        </>
      )}
    {currentView === 'controllibrary' && (
        <>
          <ControlLibrary />
        </>
      )}
      {currentView === 'issues' && (
        <>
          <Issues />
        </>
      )} 
      {currentView === 'scoping' && (
        <>
          <Scoping />
        </>
      )}
      {currentView === 'live' && (
        <>
          <Live />
        </>
      )}
    </div>
  );
};

export default CenterContent; 