import React from 'react';
import './EndpointDetectionAndResponse.css';

const EndpointDetectionAndResponse = () => {
  return (
    <div className="edr-container">

      <div className="edr-overview-section">
        <div className="edr-card">
          <h2>THREAT OVERVIEW</h2>
          <p>Active Threats: <strong>67</strong></p>
          <p>Security Levels: <strong>Critical</strong></p>
          <p>Threat Type: <strong>Malware</strong></p>
        </div>
        <div className="edr-card">
          <h2>INCIDENT SUMMARY</h2>
          <p>Number of Incidents: <strong>13</strong></p>
          <p>Status of Incidents: <strong>In Progress</strong></p>
        </div>
        <div className="edr-card">
          <h2>ENDPOINT HEALTH</h2>
          <p>Number of Endpoints: <strong>20</strong></p>
          <p>Compromised Endpoints: <strong>13</strong></p>
          <p>Endpoint Status: <strong>At Risk</strong></p>
        </div>
      </div>

      <div className="edr-section">
        <h2>SECURITY ALERTS</h2>
        <div className="edr-alerts">
          <p>Recent Alerts</p>
          <ul>
            <li>Something crazy happen with your security. Check your data</li>
            <li>There was a breach. Time to call the stakeholders.</li>
            <li>There was a breach. Time to call the media.</li>
          </ul>
          <p>Escalated Alerts</p>
          <ul>
            <li>Something crazy happen with your security. Check your data</li>
            <li>There was a breach. Time to call the stakeholders.</li>
            <li>There was a breach. Time to call the media.</li>
          </ul>
        </div>
      </div>

      <div className="edr-section">
        <h2>RESPONSE ACTIONS</h2>
        <div className="edr-alerts">
          <p>Pending Response Actions</p>
          <ul>
            <li>Something crazy happen with your security. Check your data</li>
            <li>There was a breach. Time to call the stakeholders.</li>
            <li>There was a breach. Time to call the media.</li>
          </ul>
          <p>Response Effectiveness</p>
          <p>Several of your company’s alerts are currently operating on outdated versions of their respective operating systems, and a number of these devices are missing critical security patches. This could potentially expose the organization to vulnerabilities, as these systems may lack the necessary updates to protect against the latest threats and ensure optimal performance.</p>
        </div>
      </div>

      <div className="edr-section">
        <h2>VULNERABILITY MANAGEMENT</h2>
        <div className="edr-alerts">
          <p>Critical Vulnerabilities</p>
          <ul>
            <li>Something crazy happen with your security. Check your data</li>
            <li>There was a breach. Time to call the stakeholders.</li>
            <li>There was a breach. Time to call the media.</li>
          </ul>
          <p>Patch Status</p>
          <p>Several of your company’s alerts are currently operating on outdated versions of their respective operating systems, and a number of these devices are missing critical security patches. This could potentially expose the organization to vulnerabilities, as these systems may lack the necessary updates to protect against the latest threats and ensure optimal performance.</p>
        </div>
      </div>

      <div className="edr-section">
        <h2>USER AND ENDPOINT GROUPS</h2>
        <div className="edr-alerts">
          <p>Risky Users</p>
          <ul>
            <li>Something crazy happen with your security. Check your data</li>
            <li>There was a breach. Time to call the stakeholders.</li>
            <li>There was a breach. Time to call the media.</li>
          </ul>
          <p>Endpoint Groups</p>
          <p>Several of your company’s alerts are currently operating on outdated versions of their respective operating systems, and a number of these devices are missing critical security patches. This could potentially expose the organization to vulnerabilities, as these systems may lack the necessary updates to protect against the latest threats and ensure optimal performance.</p>
        </div>
      </div>

      <div className="edr-section">
        <h2>NETWORK TRAFFIC OVERVIEW</h2>
        <div className="edr-alerts">
          <p>Suspicious Traffic</p>
          <ul>
            <li>Something crazy happen with your security. Check your data</li>
            <li>There was a breach. Time to call the stakeholders.</li>
            <li>There was a breach. Time to call the media.</li>
          </ul>
          <p>Blocked Connections</p>
          <p>Several of your company’s alerts are currently operating on outdated versions of their respective operating systems, and a number of these devices are missing critical security patches. This could potentially expose the organization to vulnerabilities, as these systems may lack the necessary updates to protect against the latest threats and ensure optimal performance.</p>
        </div>
      </div>

      <div className="edr-section">
        <h2>COMPLIANCE STATUS</h2>
        <div className="edr-alerts">
          <p>Regulatory Compliance</p>
          <ul>
            <li>Something crazy happen with your security. Check your data</li>
            <li>There was a breach. Time to call the stakeholders.</li>
            <li>There was a breach. Time to call the media.</li>
          </ul>
          <p>Endpoint Compliance</p>
          <p>Several of your company’s alerts are currently operating on outdated versions of their respective operating systems, and a number of these devices are missing critical security patches. This could potentially expose the organization to vulnerabilities, as these systems may lack the necessary updates to protect against the latest threats and ensure optimal performance.</p>
        </div>
      </div>

      <div className="edr-section">
        <h2>HISTORICAL DATA AND TRENDS</h2>
        <div className="edr-alerts">
          <p>Incident Trends</p>
          <ul>
            <li>Something crazy happen with your security. Check your data</li>
            <li>There was a breach. Time to call the stakeholders.</li>
            <li>There was a breach. Time to call the media.</li>
          </ul>
          <p>Threat Vectors</p>
          <p>Several of your company’s alerts are currently operating on outdated versions of their respective operating systems, and a number of these devices are missing critical security patches. This could potentially expose the organization to vulnerabilities, as these systems may lack the necessary updates to protect against the latest threats and ensure optimal performance.</p>
        </div>
      </div>

      <div className="edr-section">
        <h2>MALWARE ACTIVITY</h2>
        <div className="edr-overview-section">
          <div className="edr-card">
            <h2>Infected Assets</h2>
            <p><strong>67</strong></p>
          </div>
          <div className="edr-card">
            <h2>Assets Monitored</h2>
            <p><strong>15.1k</strong></p>
          </div>
          <div className="edr-card">
            <h2>New Malware Today</h2>
            <p><strong>6</strong></p>
          </div>
          <div className="edr-card">
            <h2>Malware Families</h2>
            <p><strong>19</strong></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EndpointDetectionAndResponse;