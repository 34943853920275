import React from 'react';
import './AccessReviewHeader.css';

const AccessReviewHeader = () => {
  return (
    <div className="access-header">
      <h1 className="access-title">User Access Reviews</h1>
    </div>
  );
};

export default AccessReviewHeader;