import React from 'react';
import './AssetManagement.css';
import AssetManagementHeader from './AssetManagementHeader';

const AssetManagement = () => {
  return (
    <div className="asset-management-container">
      <AssetManagementHeader />

      <div className="asset-summary">
        <h2>Asset Inventory Summary</h2>
        <div className="asset-info">
          <p>Assets by Category</p>
          <ul>
            <li>Something crazy happen with your security. Check your data.</li>
            <li>There was a breach. Time to call the stakeholders.</li>
            <li>There was a breach. Time to call the media.</li>
          </ul>
          <p>Total Number of Assets</p>
          <p>Several of your company's devices are currently operating on outdated versions of their respective operating systems...</p>
        </div>
      </div>

      <div className="asset-stats">
        <div className="asset-stat">
          <h3>Operational Status</h3>
          <p>Assets Active: 18</p>
          <p>Assets Inactive: 11</p>
          <p>Assets Decommissioned: 8</p>
        </div>
        <div className="asset-stat">
          <h3>Patch Status Summary</h3>
          <p>Assets In Use: 23</p>
          <p>End-of-Life Assets: 9</p>
        </div>
      </div>

      <div className="accordion-section">
        <div className="accordion">
          <div className="accordion-header">Location and Ownership</div>
        </div>
        <div className="accordion">
          <div className="accordion-header">Asset Lifecycle Management</div>
        </div>
        <div className="accordion">
          <div className="accordion-header">Financial Overview</div>
        </div>
        <div className="accordion">
          <div className="accordion-header">Compliance and Risk</div>
        </div>
        <div className="accordion">
          <div className="accordion-header">Asset Utilization</div>
        </div>
        <div className="accordion">
          <div className="accordion-header">Software License Management</div>
        </div>
        <div className="accordion">
          <div className="accordion-header">Asset Disposal and Decommissioning</div>
        </div>
        <div className="accordion">
          <div className="accordion-header">Alerts and Notifications</div>
        </div>
      </div>
    </div>
  );
};

export default AssetManagement;