import React from 'react';
import './Patching.css'; 

const Patching = () => {
  return (
    <div className="patching-container">
      
      <div className="patching-overview">
        <div className="patching-stat">
          <h2>Patch Compliance Overview</h2>
          <p>Overall Compliance Rate</p>
          <h3>67%</h3>
          <p>Devices with Missing Patches</p>
          <h3>17</h3>
        </div>
        <div className="patching-stat">
          <h2>Patch Status Summary</h2>
          <p>Pending Patches</p>
          <h3>23</h3>
          <p>Failed Patches</p>
          <h3>9</h3>
          <p>Successful Patch Installations</p>
          <h3>20</h3>
        </div>
        <div className="patching-stat">
          <h2>Devices at Risk</h2>
          <h3>32</h3>
          <ul>
            <li>Unpatched Critical Vulnerabilities 1</li>
            <li>Unpatched Critical Vulnerabilities 2</li>
            <li>Unpatched Critical Vulnerabilities 3</li>
          </ul>
        </div>
      </div>

      <div className="patching-details">
        <div className="patching-section">
          <h3>Patch Management Status</h3>
          <p>Deployment Progress</p>
          <p>Scheduled Patches</p>
          <p>Patch Rollback Information</p>
        </div>

        <div className="patching-section">
          <h3>Patch Health and Compliance</h3>
          <p>Non-Compliant Devices</p>
          <p>Patch Timely Status</p>
        </div>

        <div className="patching-section">
          <h3>OS and Application Status</h3>
          <p>Operating System Rollouts</p>
          <p>Third-Party Application Patches</p>
        </div>

        <div className="patching-section">
          <h3>Automated vs Manual Patching</h3>
          <p>Operating System Patches</p>
          <p>Third-Party Application Patches</p>
        </div>

        <div className="patching-section">
          <h3>Alerts and Notifications</h3>
          <p>Pending Patch Reviews</p>
          <p>Escalated Alerts</p>
        </div>
      </div>
    </div>
  );
};

export default Patching;