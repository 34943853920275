import React, { useState } from 'react';
import './Header.css';
import ScheduleDemoModal from './ScheduleDemoModal';

const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  return (
    <header className="header">
      <a href="/" className="logo"></a>
      <button
        className={`mobile-menu-icon ${isMobileMenuOpen ? 'open' : ''}`}
        onClick={toggleMobileMenu}
      >
        {isMobileMenuOpen ? '✕' : '☰'}
      </button>
      <nav className={isMobileMenuOpen ? 'nav-mobile open' : 'nav-mobile'}>
        <ul>
          <li><a href="/" onClick={toggleMobileMenu}>Home</a></li>
          <li><a href="/about" onClick={toggleMobileMenu}>About Us</a></li>
          <li><a href="/blog" onClick={toggleMobileMenu}>Blog</a></li>
          <li><a href="/login" onClick={toggleMobileMenu}>Log in</a></li>
          <li><button onClick={() => { toggleModal(); toggleMobileMenu(); }} className="btn-schedule">Schedule a demo</button></li>
        </ul>
      </nav>
      <ScheduleDemoModal isOpen={isModalOpen} onClose={toggleModal} />
    </header>
  );
};

export default Header;