// firebase-config.js

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';

// Original Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCwAsTYWsF--uwkRdx_UkjKWIJk28obGWM",
  authDomain: "vayu-208fb.firebaseapp.com",
  projectId: "vayu-208fb",
  storageBucket: "vayu-208fb.appspot.com",
  messagingSenderId: "123442842011",
  appId: "1:123442842011:web:e65240aae3ebf9a28f393e",
  measurementId: "G-137RFKESXP"
};

// SAML-specific Firebase configuration
const samlFirebaseConfig = {
  apiKey: "AIzaSyBMC2HlgCwX2Vin3QsdKoYps64yPomlpF4",
  authDomain: "eastern-rain-431114-t2.firebaseapp.com",
  projectId: "eastern-rain-431114-t2",
  storageBucket: "eastern-rain-431114-t2.appspot.com",
  messagingSenderId: "307699856776",
  appId: "1:307699856776:web:6345b9bf680641f1a1b63d",
  measurementId: "G-F1NFT1KFMJ"
};

// Initialize original Firebase app
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

// Initialize the SAML-specific Firebase app
const samlApp = initializeApp(firebaseConfig, 'samlApp');
const samlAuth = getAuth(samlApp);
const analytics = getAnalytics(samlApp); // Optional, if you want to use analytics

// Export both Firebase app instances
export { auth, db, storage, firebaseConfig }; 