import React, { useState, useEffect } from 'react';
import './TodoListComponent.css';  // Reusing the same CSS file
import { auth } from '../firebase'; // Ensure this imports your Firebase auth

const ToDoListActionArea = () => {
  const [todos, setTodos] = useState([]);

  const baseUrl =
    process.env.NODE_ENV === 'production'
      ? 'https://godfirst-vayu-app-qravzvgjba-uc.a.run.app'
      : 'http://localhost:8080';

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        // Fetch the currently logged-in user
        const currentUser = auth.currentUser;
        if (currentUser) {
          const userResponse = await fetch(`${baseUrl}/api/users?email=${currentUser.email}`, {
            headers: {
              'Content-Type': 'application/json',
            },
          });

          if (!userResponse.ok) {
            throw new Error(`HTTP error! status: ${userResponse.status}`);
          }

          const userData = await userResponse.json();
          const userOrgId = userData[0]?.orgId;

          if (userOrgId) {
            // Fetch tasks linked to the user's org
            const tasksResponse = await fetch(`${baseUrl}/api/tasks?orgId=${userOrgId}`, {
              headers: {
                'Content-Type': 'application/json',
              },
            });

            if (!tasksResponse.ok) {
              throw new Error(`HTTP error! status: ${tasksResponse.status}`);
            }

            const tasksData = await tasksResponse.json();

            // Group tasks by compliance framework
            const groupedTasks = tasksData.reduce((acc, task) => {
              const framework = task.framework || 'Other';
              if (!acc[framework]) {
                acc[framework] = [];
              }
              acc[framework].push(task);
              return acc;
            }, {});

            // Convert grouped tasks into the required structure
            const todosData = Object.keys(groupedTasks).map(framework => ({
              type: framework,
              items: groupedTasks[framework]
            }));

            setTodos(todosData);
          }
        } else {
          console.error('No user is currently signed in.');
        }
      } catch (error) {
        console.error('Failed to fetch tasks:', error);
      }
    };

    fetchTasks();
  }, [baseUrl]);

  return (
    <div className="todo-list-component-custom no-tasks-fill">
      <div className="todo-list-header-row-custom">
        <h2 className="todo-list-title-custom">Daily To-Do List</h2>
        
      </div>
      {todos.length === 0 ? (
        <div className="no-tasks-container">
          <p className="no-tasks-message">No tasks yet. Check back soon!</p>
          <img src="https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExdDlxNG56b3ZqcmR3cGx1enoxMjVkbmRwZmdmdnVjNDE5aTdsc2xheiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/pWhWtKdqwOAco/giphy.gif" alt="No Tasks" className="no-tasks-gif" />
        </div>
      ) : (
        todos.map((section, index) => (
          <div key={index} className="todo-section">
            <div className="todo-list-header-row-custom">
              <h2 className="todo-list-title-custom">{section.type}</h2>
              <a 
                href="#" 
                className={`todo-list-view-all-custom ${section.items.length === 0 ? 'view-all-disabled' : ''}`}
                onClick={(e) => section.items.length === 0 && e.preventDefault()}
              >
                View all <img src="/Vayu Chevron.svg" alt="Chevron" className="todo-list-chevron-icon" />
              </a>
            </div>
            <ul className="todo-list-items-custom">
              {section.items.map((item, itemIndex) => (
                <li key={item.id} className="todo-list-item-custom">
                  <div className="todo-list-details-custom">
                    <p className="todo-list-task-name-custom">{item.title}</p> {/* Now showing the task title */}
                    <p className="todo-list-task-desc-custom">{item.id}</p> {/* Now showing the task ID */}
                    <div className="todo-list-meta-custom">
                      <p className="todo-list-task-date-custom">{new Date(item.dueDate).toDateString()}</p>
                      <span
                        className={`todo-list-status-badge-custom ${
                          item.status === 'In progress' ? 'todo-list-status-inprogress' : ''
                        }`}
                      >
                        {item.status}
                      </span>
                      <span className="todo-list-type-badge-custom">{item.framework}</span>
                    </div>
                  </div>
                  <div className="todo-list-user-custom">
                    <img src="/Vayu 5.png" alt="User Avatar" className="todo-list-user-avatar-custom" />
                    <span className="todo-list-more-options-custom">&#8942;</span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ))
      )}
    </div>
  );
};

export default ToDoListActionArea; 