import React from 'react';
import './AccessReviews.css';

const AccessReviews = () => {
  const accessList = [
    { vendor: 'AWS (Amazon Web Services)', reviewer: 'Anish Kumar', status: 'Connected', logo: '/awslogo.svg' },
    { vendor: 'Airtable', reviewer: 'Bradley Cooper', status: 'Pending', logo: '/airtable.svg' },
    { vendor: 'Salesforce', reviewer: 'Ashley Banks', status: 'Pending', logo: '/salesforcelogo.svg' },
    { vendor: 'Microsoft Azure', reviewer: 'Jane Simpson', status: 'Inactive', logo: '/azurelogo.svg' },
  ];

  return (
    <div className="access-review-container">
      <div className="header-section">
        <h2>Aug 14, 2024</h2>
        <div className="draft-status-container">
          <button className="draft-status">Draft</button>
        </div>
      </div>
      <div className="progress-section">
        <span>Draft</span>
        <span className="progress-circle"></span>
        <span>In review</span>
        <span className="progress-circle"></span>
        <span>Complete</span>
        <button className="start-review-button">Start Review</button>
      </div>

      <table className="vendor-table">
        <thead>
          <tr>
            <th>VENDOR</th>
            <th>REVIEWER</th>
            <th>ACCESS FILE</th>
            <th>STATUS</th>
          </tr>
        </thead>
        <tbody>
          {accessList.map((item, index) => (
            <tr key={index}>
              <td>
                <img src={item.logo} alt={`${item.vendor} Logo`} /> {item.vendor}
              </td>
              <td>{item.reviewer}</td>
              <td>
                {item.status === 'Connected' || item.status === 'Pending' ? (
                  <button className="view-button">View</button>
                ) : (
                  <button className="add-button">Add +</button>
                )}
              </td>
              <td className={`status-${item.status.toLowerCase()}`}>● {item.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AccessReviews;