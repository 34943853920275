import React, { useState, useEffect } from 'react';
import ComplianceTile from './ComplianceTile';
import './OverviewComponent.css';
import { auth } from '../firebase';
import { useGlobalState } from '../context/GlobalStateContext';

const OverviewComponent = ({ onTileClick }) => {
  const [complianceFrameworks, setComplianceFrameworks] = useState([]);

  // Access global state
  const { isAuditor, setIsAuditor, setClientId } = useGlobalState();

  const baseUrl = process.env.NODE_ENV === 'production'
    ? 'https://godfirst-vayu-app-qravzvgjba-uc.a.run.app'
    : 'http://localhost:8080';

  useEffect(() => {
    const fetchComplianceFrameworks = async () => {
      try {
        const currentUser = auth.currentUser;
        console.log('Current user:', currentUser);

        if (currentUser) {
          const response = await fetch(`${baseUrl}/api/users?email=${currentUser.email}`);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          const userData = data[0] || {};
          console.log('Fetched user data:', userData);

          if (userData.orgId) {
            console.log('User orgId (Document ID):', userData.orgId);
            const orgResponse = await fetch(`${baseUrl}/api/orgs/${userData.orgId}`);
            if (!orgResponse.ok) {
              throw new Error(`HTTP error! status: ${orgResponse.status}`);
            }
            const orgData = await orgResponse.json();
            console.log('Fetched org data:', orgData);
            setIsAuditor(orgData.type === 'auditor');

            if (orgData.type === 'client') {
              console.log('User is a client, fetching their own compliance frameworks...');
              const clientFrameworks = await fetchClientFrameworks(userData.orgId);
              setComplianceFrameworks(clientFrameworks);
            } else if (orgData.type === 'auditor') {
              console.log('User is an auditor, fetching linked clients...');
              const linkedClientsFrameworks = await fetchLinkedClientsFrameworks(orgData.linkedClients);
              setComplianceFrameworks(linkedClientsFrameworks);
            } else if (orgData.type === 'vayu') {
              console.log('Organization type is vayu, automatically linking all client organizations...');
              const allClientOrgsFrameworks = await fetchAllClientOrgsFrameworks();
              setComplianceFrameworks(allClientOrgsFrameworks);
            } else if (orgData.complianceFramework) {
              console.log('User is not an auditor, using org compliance frameworks...');
              setComplianceFrameworks(orgData.complianceFramework.map(framework => ({
                framework,
                clientName: orgData.name,
                clientId: userData.orgId,
                progress: 0,
                total: 0
              })));
            }
          }
        } else {
          console.error('No user is currently signed in.');
        }
      } catch (error) {
        console.error('Failed to fetch user or org info:', error);
      }
    };

    fetchComplianceFrameworks();
  }, [baseUrl, setIsAuditor]);
 
  const fetchClientFrameworks = async (clientId) => {
    console.log('fetchClientFrameworks called for client ID:', clientId);
    const frameworks = [];
    
    try {
      const clientResponse = await fetch(`${baseUrl}/api/clients/${clientId}`);
      if (clientResponse.ok) {
        const clientData = await clientResponse.json();
        console.log('Client data:', clientData);
  
        const clientFrameworks = clientData.complianceFramework || [];
        if (clientFrameworks.length > 0) {
          clientFrameworks.forEach(framework => {
            frameworks.push({
              framework,
              clientName: clientData.name,
              clientId: clientId,
              progress: 0,
              total: 0,
              isClickable: true
            });
          });
        } else {
          frameworks.push({
            framework: "unknown",
            clientName: clientData.name,
            clientId: clientId,
            progress: 0,
            total: 0,
            isClickable: false
          });
        }
      } else {
        console.error(`Failed to fetch frameworks for client ID: ${clientId}, Status: ${clientResponse.status}`);
      }
    } catch (error) {
      console.error(`Error fetching frameworks for client ID: ${clientId}:`, error);
    }
  
    console.log('Frameworks for client:', frameworks);
    return frameworks;
  }; 
   
  const fetchLinkedClientsFrameworks = async (linkedClients) => {
    console.log('fetchLinkedClientsFrameworks called');
    const frameworks = [];
    if (!linkedClients) return frameworks; // Defensive check
  
    for (const clientId of linkedClients) {
      try {
        console.log('Fetching data for client ID:', clientId);
        const clientResponse = await fetch(`${baseUrl}/api/clients/${clientId}`);
        if (clientResponse.ok) {
          const clientData = await clientResponse.json();
          console.log('Client data for auditor:', clientData);
  
          const clientFrameworks = clientData.complianceFramework || [];
          if (clientFrameworks.length > 0) {
            clientFrameworks.forEach(framework => {
              frameworks.push({
                framework,
                clientName: clientData.name,
                clientId: clientId,
                progress: 0,
                total: 0,
                isClickable: true
              });
            });
          } else {
            console.warn(`No compliance frameworks found for client ID: ${clientId}`);
            // Add only if there are absolutely no frameworks
            frameworks.push({
              framework: "unknown",
              clientName: clientData.name,
              clientId: clientId,
              progress: 0,
              total: 0,
              isClickable: false
            });
          }
        } else {
          console.error(`Failed to fetch frameworks for client ID: ${clientId}, Status: ${clientResponse.status}`);
        }
      } catch (error) {
        console.error(`Error fetching frameworks for client ID: ${clientId}:`, error);
      }
    }
    console.log('Frameworks for auditor:', frameworks);
    return frameworks;
  }; 

  const fetchAllClientOrgsFrameworks = async () => {
    console.log('fetchAllClientOrgsFrameworks called');
    const frameworks = [];
    try {
      const clientsResponse = await fetch(`${baseUrl}/api/orgs?type=client`);
      if (clientsResponse.ok) {
        const clientsData = await clientsResponse.json();
        console.log('All clients data for vayu:', clientsData);
  
        for (const client of clientsData) {
          try {
            const clientFrameworks = client.complianceFramework || [];
            console.log('QA: ');
            console.log(clientFrameworks);
            console.log('Client data for vayu:', client);
  
            if (clientFrameworks.length > 0) {
              clientFrameworks.forEach(framework => {
                frameworks.push({
                  framework,
                  clientName: client.name,
                  clientId: client.id,
                  progress: 0,
                  total: 0,
                  isClickable: true
                });
              });
            } else {
              console.warn(`No compliance frameworks found for client ID: ${client.id}`);
              frameworks.push({
                framework: "unknown",
                clientName: client.name,
                clientId: client.id,
                progress: 0,
                total: 0,
                isClickable: false
              });
            }
          } catch (error) {
            console.error(`Error processing client ID: ${client.id}:`, error);
          }
        }
  
      } else {
        console.error(`Failed to fetch all client organizations, Status: ${clientsResponse.status}`);
      }
    } catch (error) {
      console.error('Error fetching all client organizations:', error);
    }
    console.log('Frameworks for vayu:', frameworks);
    return frameworks;
  }; 

  return (
    <div className="overview-component">
      <h2>Overview</h2>
      <div className="overview-slider">
        {complianceFrameworks && complianceFrameworks.length > 0 ? (
          complianceFrameworks.map((frameworkData, index) => (
            <ComplianceTile
              key={`${frameworkData.clientName}-${frameworkData.framework}-${index}`}
              title={frameworkData.framework}
              progress={frameworkData.progress}
              total={frameworkData.total}
              backgroundColor="#E4E7DE"
              progressColor="#3E4936"
              clientNames={[frameworkData.clientName]}  // Always show client names for 'vayu' orgs
              onClick={
                frameworkData.isClickable
                  ? () => {
                      console.log('Tile clicked:', frameworkData);
                      console.log('isAuditor: ' + isAuditor);
                      setClientId(frameworkData.clientId);
                      onTileClick(frameworkData.framework, frameworkData.clientName, frameworkData.clientId);
                    }
                  : null
              }
            />
          ))
        ) : (
         
          <div className="cyberpunk-loader"><div className="cyberpunk-bar"></div></div> 
        )}
      </div>
    </div>
  );
};

export default OverviewComponent; 