import React, { useState, useEffect } from 'react';
import './Live.css';

const vendorsData = [
  { name: 'Vendor A', control: 'SOC2 Control 1', link: '#control1', reason: 'Monitoring access controls and ensuring compliance.' },
  { name: 'Vendor B', control: 'SOC2 Control 2', link: '#control2', reason: 'Reviewing backup and disaster recovery plans.' },
  { name: 'Vendor C', control: 'SOC2 Control 3', link: '#control3', reason: 'Evaluating incident response and mitigation processes.' },
];

const initialIncidents = [
  { id: 1, title: 'Unauthorized access detected', action: 'Investigate', status: 'Open', reason: 'A potential breach occurred, immediate investigation is required.' },
  { id: 2, title: 'Backup failed', action: 'Retry Backup', status: 'Resolved', reason: 'The scheduled backup didn’t complete, needs to be retried.' },
];

const Live = () => {
  const [realTimeFeed, setRealTimeFeed] = useState([]);
  const [incidents, setIncidents] = useState(initialIncidents);

  useEffect(() => {
    // Mock real-time data feed
    const feedInterval = setInterval(() => {
      const newActivity = {
        id: realTimeFeed.length + 1,
        text: `New activity detected from Vendor ${['A', 'B', 'C'][Math.floor(Math.random() * 3)]}`,
        timestamp: new Date().toLocaleTimeString(),
        control: `SOC2 Control ${Math.floor(Math.random() * 3) + 1}`,
        link: '#control' + Math.floor(Math.random() * 3 + 1),
        reason: 'Monitoring vendor activity to ensure compliance with SOC2 standards.'
      };
      setRealTimeFeed((prevFeed) => [newActivity, ...prevFeed].slice(0, 10)); // Limit to last 10 activities
    }, 3000);

    return () => clearInterval(feedInterval);
  }, [realTimeFeed]);

  const handleIncidentAction = (incidentId) => {
    setIncidents(incidents.map((incident) => 
      incident.id === incidentId ? { ...incident, status: 'Actioned' } : incident
    ));
  };

  const handleLinkClick = (link) => {
    alert(`Navigating to: ${link}`);
    // Implement actual navigation or further logic here if needed.
  };

  return (
    <div className="live-container">
      <div className="live-header">
        <h1 className="live-title">Live Vendor Monitoring</h1>
      </div>

      <div className="live-content">
        <div className="live-feed-section">
          <h2 className="section-title">Real-Time Feed</h2>
          <div className="live-feed">
            {realTimeFeed.map((feedItem) => (
              <div className="feed-item" key={feedItem.id}>
                <p>
                  {feedItem.text}{' '}
                  <a href={feedItem.link} onClick={() => handleLinkClick(feedItem.link)}>
                    View {feedItem.control}
                  </a>
                </p>
                <span className="timestamp">{feedItem.timestamp}</span>
                <p className="reason">{feedItem.reason}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="vendor-table-section">
          <h2 className="section-title">Vendors and SOC2 Controls</h2>
          <table className="vendor-table">
            <thead>
              <tr>
                <th>Vendor Name</th>
                <th>SOC2 Control</th>
                <th>Why Monitor?</th>
              </tr>
            </thead>
            <tbody>
              {vendorsData.map((vendor, index) => (
                <tr key={index}>
                  <td>{vendor.name}</td>
                  <td><a href={vendor.link} onClick={() => handleLinkClick(vendor.link)}>{vendor.control}</a></td>
                  <td>{vendor.reason}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="incident-section">
          <h2 className="section-title">Incidents</h2>
          {incidents.map((incident) => (
            <div className="incident-item" key={incident.id}>
              <p>{incident.title}</p>
              <span className="incident-reason">{incident.reason}</span>
              <button 
                onClick={() => handleIncidentAction(incident.id)} 
                disabled={incident.status !== 'Open'}
                className={`incident-action-btn ${incident.status !== 'Open' ? 'disabled' : ''}`}
              >
                {incident.status === 'Open' ? incident.action : 'Actioned'}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Live; 