import React from 'react';
import './UserPermissions.css';

const UserPermissions = () => {
  return (
    <div className="user-permissions-container">
      <h2 className="user-permissions-title">Active Users</h2>
      <table className="user-permissions-table">
        <thead>
          <tr>
            <th className="user-permissions-header">NAME</th>
            <th className="user-permissions-header">EMAIL</th>
            <th className="user-permissions-header">ROLE</th>
            <th className="user-permissions-header"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Jennifer Aniston</td>
            <td>jen@gmail.com</td>
            <td>
              <select className="user-role-select" defaultValue="Admin">
                <option value="Admin">Admin</option>
                <option value="Editor">Editor</option>
                <option value="Viewer">Viewer</option>
              </select>
            </td>
            <td>
              <button className="user-action-button delete-button">&#128465;</button>
            </td>
          </tr>
          <tr>
            <td>Ross Geller</td>
            <td>ross@gmail.com</td>
            <td>
              <select className="user-role-select" defaultValue="Admin">
                <option value="Admin">Admin</option>
                <option value="Editor">Editor</option>
                <option value="Viewer">Viewer</option>
              </select>
            </td>
            <td>
              <button className="user-action-button delete-button">&#128465;</button>
            </td>
          </tr>
          <tr>
            <td>Monica Geller</td>
            <td>monica@gmail.com</td>
            <td>
              <select className="user-role-select" defaultValue="Admin">
                <option value="Admin">Admin</option>
                <option value="Editor">Editor</option>
                <option value="Viewer">Viewer</option>
              </select>
            </td>
            <td>
              <button className="user-action-button delete-button">&#128465;</button>
            </td>
          </tr>
          <tr>
            <td>Phoebe Buffay</td>
            <td>phoebe@gmail.com</td>
            <td>
              <select className="user-role-select" defaultValue="Editor">
                <option value="Admin">Admin</option>
                <option value="Editor">Editor</option>
                <option value="Viewer">Viewer</option>
              </select>
            </td>
            <td>
              <button className="user-action-button delete-button">&#128465;</button>
            </td>
          </tr>
        </tbody>
      </table>

      <button className="invite-user-button">Invite User +</button>

      <h2 className="user-permissions-title">Pending Invites</h2>
      <table className="user-permissions-table">
        <tbody>
          <tr>
            <td>Brooklyn Kotha</td>
            <td>brooklyn@welovedogs.com</td>
            <td>
              <button className="user-action-button resend-button">Resend Invite</button>
              <button className="user-action-button revoke-button">Revoke Invite</button>
            </td>
          </tr>
          <tr>
            <td>Henry Gustafsson</td>
            <td>henry@welovedogs.com</td>
            <td>
              <button className="user-action-button resend-button">Resend Invite</button>
              <button className="user-action-button revoke-button">Revoke Invite</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default UserPermissions;