import React, { useEffect } from 'react';

const ChatDashboardVisibilityController = ({ isVisible }) => {
  useEffect(() => {
    const chatBubble = document.getElementById('dify-chatbot-bubble-button');
    if (chatBubble) {
      if (isVisible) {
        chatBubble.style.display = 'block';
      } else {
        chatBubble.style.display = 'none'; 
      }
    }
  }, [isVisible]);

  return null;
};

export default ChatDashboardVisibilityController;