import React from 'react';
import './DocumentsHeader.css';

const DocumentsHeader = () => {
  return (
    <div className="documents-header">
      <h1 className="documents-title">Documents</h1>
    </div>
  );
};

export default DocumentsHeader;