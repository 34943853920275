import React, { useState, useEffect } from 'react';
import './AuditMaterialComponent.css';
import { getAuth } from 'firebase/auth'; // Firebase Auth to get user email

const AuditMaterialComponent = () => {
  const [activeTab, setActiveTab] = useState('tab1');
  const [selectedCellData, setSelectedCellData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userEmailDomain, setUserEmailDomain] = useState('');

  // Fetch the current user's email and extract the domain
  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user && user.email) {
      const emailDomain = user.email.split('@')[0];
      setUserEmailDomain(emailDomain);
    }
  }, []);

  // Sample data for the table (SOC2 evidence and testing)
  const tableData = [
    // Sample rows can be added here
  ];

  // Function to open the modal when a cell is clicked
  const handleCellClick = (data) => {
    setSelectedCellData(data);
    setIsModalOpen(true);
  };

  // Determine which chatbot URL to use based on the email domain
  const chatbotUrl = userEmailDomain.includes('admin')
    ? 'https://udify.app/chatbot/e1ZO8byW0nAqD8oj'
    : 'https://udify.app/chatbot/7JIsNzq5e0FabT8u';

  return (
    <div className="audit-demo-container">
      <div className="tabs">
        <button className={activeTab === 'tab1' ? 'active' : ''} onClick={() => setActiveTab('tab1')}>
          Prepared Audit (SOC 2 Type 1)
        </button>
        <button className={activeTab === 'tab2' ? 'active' : ''} onClick={() => setActiveTab('tab2')}>
          Section 3 (Agent + Document)
        </button>
        <button className={activeTab === 'tab3' ? 'active' : ''} onClick={() => setActiveTab('tab3')}>
          Section 4 (Evidence Collection + Testing)
        </button>
      </div>

      {activeTab === 'tab1' && (
        <div className="tab-content">
          <div className="side-by-side">
            <iframe
              src={chatbotUrl}
              style={{ width: '35%', height: '100%', minHeight: '600px' }}
              frameBorder="0"
              allow="microphone"
            ></iframe>
            <iframe
              src="https://docs.google.com/document/d/1Q0VFqANYOPMYgDjY2_O8yQzkd6DMdMByH0pmzgMQYLw/preview"
              title="Google Doc Preview"
              width="65%"
              height="600"
              style={{ border: 'none' }}
            ></iframe>
          </div>
        </div>
      )}

      {activeTab === 'tab2' && (
        <div className="tab-content">
          <div className="side-by-side">
            <iframe
              src={chatbotUrl}
              style={{ width: '35%', height: '100%', minHeight: '600px' }}
              frameBorder="0"
              allow="microphone"
            ></iframe>
            <iframe
              src="https://docs.google.com/document/d/1Vr-_p0ELaCnar9153Gub_mtWlkbekuIY0rGKoYwWebU/preview"
              title="Google Doc Preview"
              width="65%"
              height="600"
              style={{ border: 'none' }}
            ></iframe>
          </div>
        </div>
      )}

      {activeTab === 'tab3' && (
        <div className="tab-content">
          <div className="side-by-side">
            <div className="table-container">
              <table className="soc2-table">
                <thead>
                  <tr>
                    <th>Trust ID</th>
                    <th>COSO Principle</th>
                    <th>Control Description</th>
                    <th>Test Applied by Service Auditor</th>
                    <th>Test Results</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((row, index) => (
                    <tr key={index}>
                      <td>{row.trustId}</td>
                      <td>{row.cosoPrinciple}</td>
                      <td onClick={() => handleCellClick(row)}>{row.description}</td>
                      <td onClick={() => handleCellClick(row)}>{row.auditorTest}</td>
                      <td></td>
                    </tr>
                  ))}
                </tbody>
              </table>
              AssetTrack Provisioning (Step: SSO) in progress. Please check back again soon.
            </div>
          </div>
        </div>
      )}

      {/* Modal for Evidence */}
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={() => setIsModalOpen(false)}>&times;</span>
            <h2>Evidence Details</h2>
            <p><strong>Trust ID:</strong> {selectedCellData.trustId}</p>
            <p><strong>COSO Principle:</strong> {selectedCellData.cosoPrinciple}</p>
            <p><strong>Description:</strong> {selectedCellData.description}</p>
            <p><strong>Test Applied by Auditor:</strong> {selectedCellData.auditorTest}</p>
            <p><strong>Test Results:</strong> {selectedCellData.testResults}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AuditMaterialComponent; 