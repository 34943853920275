import React from 'react';
import './IncidentReport.css';

const IncidentReport = () => {
  const incidentList = [
    { date: 'Aug 10, 2024', type: 'Phishing Attack', status: 'Resolved', description: 'Phishing email targeted employees.' },
    { date: 'Jul 25, 2024', type: 'Malware Detection', status: 'In Progress', description: 'Malware detected on the network.' },
    { date: 'Jul 15, 2024', type: 'Unauthorized Access', status: 'Resolved', description: 'Unauthorized access attempt detected.' },
    { date: 'Jun 30, 2024', type: 'Data Breach', status: 'Pending', description: 'Potential data breach reported.' },
  ];

  return (
    <div className="incident-report-container">
      <div className="header-section">
        <h2>Filed Reports</h2>
        <button className="new-report-button">New Report</button>
      </div>

      <table className="incident-table">
        <thead>
          <tr>
            <th>DATE</th>
            <th>INCIDENT TYPE</th>
            <th>STATUS</th>
            <th>DESCRIPTION</th>
          </tr>
        </thead>
        <tbody>
          {incidentList.map((incident, index) => (
            <tr key={index}>
              <td>{incident.date}</td>
              <td>{incident.type}</td>
              <td className={`status-${incident.status.toLowerCase().replace(/\s/g, '-')}`}>● {incident.status}</td>
              <td>{incident.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default IncidentReport;