import React, { useState, useEffect } from 'react';

const ClientControls = () => {
  const [orgs, setOrgs] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState('');
  const [linkedControls, setLinkedControls] = useState([]);

  const baseUrl =
    process.env.NODE_ENV === 'production'
      ? 'https://godfirst-vayu-app-qravzvgjba-uc.a.run.app'
      : 'http://localhost:8080';

  useEffect(() => {
    // Fetch organizations where type == 'client'
    const fetchOrgs = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/orgs?type=client`);
        const data = await response.json();
        setOrgs(data);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };

    fetchOrgs();
  }, [baseUrl]);

  const handleOrgSelect = async (e) => {
    const orgId = e.target.value;
    setSelectedOrg(orgId);
  
    if (orgId) {
      try {
        // Fetch linked controls for the selected organization (using the correct API)
        const response = await fetch(`${baseUrl}/api/orgs/${orgId}`);
        const orgData = await response.json();
  
        // Assuming controls are fetched via the `/api/clients/:clientId/frameworks/:framework/controls` endpoint:
        const controlsResponse = await fetch(`${baseUrl}/api/clients/${orgId}/frameworks/SOC2/controls`); // Replace 'SOC2' with the appropriate framework
        const controlsData = await controlsResponse.json();
        setLinkedControls(controlsData);
      } catch (error) {
        console.error('Error fetching linked controls:', error);
      }
    } else {
      setLinkedControls([]);
    }
  };
   

  return (
    <div className="client-controls-container">
      <div className="form-group">
        <label>Select Client</label>
        <select value={selectedOrg} onChange={handleOrgSelect}>
          <option value="">-- Select Client --</option>
          {orgs.map((org) => (
            <option key={org.id} value={org.id}>
              {org.name}
            </option>
          ))}
        </select>
      </div>

      {linkedControls.length > 0 && (
        <div className="table-container">
          <table className="controls-table">
            <thead>
              <tr>
                <th>Control ID</th>
                <th>Linked At</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {linkedControls.map((control, index) => (
                <tr key={index}>
                  <td>{control.control_id}</td>
                  <td>{control.linked_at}</td>
                  <td>{control.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ClientControls;
