import React, { useState, useEffect } from 'react';
import './Provisioning.css'; 
import { auth } from '../firebase'; // Import the Firebase auth instance

const Provisioning = () => {
  const [activeTab, setActiveTab] = useState('vayu'); // Default to 'vayu' tab
  const [orgName, setOrgName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [customNotes, setCustomNotes] = useState('');
  const [teamMembers, setTeamMembers] = useState([{ fname: '', lname: '', email: '', role: 'User' }]);
  const [complianceFramework, setComplianceFramework] = useState([]);
  const [cloudPlatform, setCloudPlatform] = useState([]);
  const [orgs, setOrgs] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState('');
  const [saving, setSaving] = useState(false); // Loader state for saving
  const [deleting, setDeleting] = useState(false); // Loader state for deleting
  const [purging, setPurging] = useState(false); // Loader state for purging
  const [linkedClients, setLinkedClients] = useState([]); // State for linked clients
  const [selectedClientToLink, setSelectedClientToLink] = useState(''); // State for the client to link  
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupContent, setPopupContent] = useState(''); 
  const [frameworks, setFrameworks] = useState([]); // Add this line to store the frameworks  
  const [cloudPlatforms, setCloudPlatforms] = useState([]); // Add this line to store the cloud platforms  
const [editingSection, setEditingSection] = useState(''); // Tracks which section is being edited 
const [teamMemberPopupVisible, setTeamMemberPopupVisible] = useState(false); // State for the team member popup  
const [newItemName, setNewItemName] = useState(''); 

const handleInfoClick = (tempPw, userSetPw) => {
  setPopupContent(
    <>
      Temporary Password: <code className="code-block">{tempPw}</code>
      <br />
      User Set Password: {userSetPw ? 'Yes' : 'No'}
    </>
  );
  setTeamMemberPopupVisible(true);
}; 

const closePopup = () => {
  setPopupVisible(false); // For compliance framework/cloud platform popup
  setTeamMemberPopupVisible(false); // For team member popup
  setEditingSection(''); // Reset editing section
}; 

  const baseUrl =
    process.env.NODE_ENV === 'production'
      ? 'https://godfirst-vayu-app-qravzvgjba-uc.a.run.app'
      : 'http://localhost:8080';

      useEffect(() => {
        if (activeTab === 'clients') {
          setSelectedOrg(''); // Clear selected org when switching to clients tab
          setOrgName('');
          setStartDate('');
          setCustomNotes('');
          setComplianceFramework([]);
          setCloudPlatform([]);
          setLinkedClients([]); // Clear linked clients
          setTeamMembers([]); // Clear team members when switching to clients tab
        } else if (activeTab === 'auditors') {
          setSelectedOrg(''); // Clear selected org when switching to auditors tab
          setOrgName('');  // Clear orgName when 'auditors' tab is selected
          setTeamMembers([]); // Clear team members when switching to auditors tab 
          setCustomNotes(''); 
        } 

        const fetchFrameworks = async () => {
          try {
            const response = await fetch(`${baseUrl}/api/frameworks`);
            const frameworksData = await response.json();
            console.log('Fetched Frameworks:', frameworksData); // Debugging output
            setFrameworks(frameworksData); // Set the frameworks in the state
          } catch (error) {
            console.error('Error fetching frameworks:', error);
          }
        };
      
        fetchFrameworks(); // Call the function to fetch frameworks 

        const fetchCloudPlatforms = async () => {
          try {
            const response = await fetch(`${baseUrl}/api/cloud-platforms`);
            const cloudPlatformsData = await response.json();
            console.log('Fetched Cloud Platforms:', cloudPlatformsData); // Debugging output
            setCloudPlatforms(cloudPlatformsData); // Set the cloud platforms in the state
          } catch (error) {
            console.error('Error fetching cloud platforms:', error);
          }
        };
      
        fetchCloudPlatforms(); // Call the function to fetch cloud platforms 
      
        const fetchOrgs = async () => { 
          const type = activeTab === 'clients' ? 'client' : activeTab === 'auditors' ? 'auditor' : 'vayu'; 
          try {
            const response = await fetch(`${baseUrl}/api/orgs?type=${type}`);
            let data = await response.json();
            console.log('Fetched Orgs:', data); // Debugging output
            data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)); // Sort by createdAt
            setOrgs(data);
        
            if (activeTab === 'auditors') {
              const clientResponse = await fetch(`${baseUrl}/api/orgs?type=client`);
              let clientData = await clientResponse.json();
              data = [...data, ...clientData];  // Combine auditor and client orgs
              setOrgs(data);
            }
            
            if (activeTab === 'vayu' && data.length > 0) {
              setSelectedOrg(data[0].id);
              setOrgName(data[0].name || '');
              const orgDataResponse = await fetch(`${baseUrl}/api/orgs/${data[0].id}`);
              const orgData = await orgDataResponse.json();
              setTeamMembers(orgData.teamMembers || [{ fname: '', lname: '', email: '', role: 'User' }]);
            }
          } catch (error) {
            console.error(`Error fetching ${type}:`, error);
          }
        }; 
      
        console.log('Active Tab:', activeTab);  // Debugging output
        fetchOrgs();
      }, [baseUrl, activeTab]); 

      const handleOrgSelect = async (e) => {
        const orgId = e.target.value;
      
        setSelectedOrg(orgId);
      
        if (orgId) {
          try {
            setSaving(true);
      
            // Fetch the details of the selected organization
            const orgDataResponse = await fetch(`${baseUrl}/api/orgs/${orgId}`);
            const orgData = await orgDataResponse.json();
      
            // Add this line to set the orgName state
            setOrgName(orgData.name || '');  // Ensure orgName is updated with the fetched organization name
            
            let teamMembersWithPw = [];
            if (orgData.teamMembers.length > 0) {
              teamMembersWithPw = await Promise.all(orgData.teamMembers.map(async (member) => {
                try {
                  const userResponse = await fetch(`${baseUrl}/api/users/${member.uid}`);
                  const userDoc = await userResponse.json();
                  return {
                    ...member,
                    tempPw: userDoc.tempPw || '', // Fetch tempPw from user document
                    userSetPw: userDoc.userSetPw || false // Fetch userSetPw from user document
                  };
                } catch (error) {
                  console.error('Error fetching user data:', error);
                  return member; // Return the member without tempPw if there's an error
                }
              }));
            }
      
            setTeamMembers(teamMembersWithPw);
      
            if (activeTab !== 'vayu') {
              setStartDate(orgData.startDate || '');
              setCustomNotes(orgData.customNotes || '');
              setComplianceFramework(orgData.complianceFramework || []);
              setCloudPlatform(orgData.cloudPlatform || []);
              setLinkedClients(orgData.linkedClients || []);
            }
          } catch (error) {
            console.error(`Error fetching ${activeTab} details:`, error);
          } finally {
            setSaving(false);
          }
        } else {
          setOrgName(''); // Clear the orgName when no organization is selected
          setStartDate('');
          setCustomNotes('');
          setTeamMembers([{ fname: '', lname: '', email: '', role: 'Admin', tempPw: '', userSetPw: false }]);
          setComplianceFramework([]);
          setCloudPlatform([]);
          setLinkedClients([]);
        }
      }; 

      const handleAddTeamMember = () => {
        const newMember = {
          tempId: `temp-${Date.now()}`, // Unique identifier for unsaved members
          fname: '',
          lname: '',
          email: '',
          role: 'Admin',
        };
        setTeamMembers([...teamMembers, newMember]);
      };   

      
      const handlePopupSubmit = async () => {
        try {
          if (editingSection === 'complianceFramework') {
            // The logic to save the selected frameworks to the server
            const selectedFrameworks = frameworks.filter(framework =>
              complianceFramework.includes(framework.id)
            );
      
            // Update server-side compliance framework for the selected org
            await fetch(`${baseUrl}/api/update-framework`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                orgId: selectedOrg,
                frameworks: complianceFramework,
              }),
            });
      
          } else if (editingSection === 'cloudPlatform') {
            // The logic to save the selected cloud platforms to the server
            const selectedPlatforms = cloudPlatforms.filter(platform =>
              cloudPlatform.includes(platform.cloud_platform_id)
            );
      
            // Update server-side cloud platforms for the selected org
            await fetch(`${baseUrl}/api/update-cloud-platform`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                orgId: selectedOrg,
                cloudPlatforms: cloudPlatform,
              }),
            });
          }
        } catch (error) {
          console.error('Error during submission:', error);
          alert('There was an error saving your changes. Please try again.');
        } finally {
          closePopup();
        }
      }; 

      const handleUnlinkClient = async (clientId) => {
        try {
          // Update the server-side data
          await fetch(`${baseUrl}/api/auditors/${selectedOrg}/unlink-client`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ clientId }),
          });
      
          // Update local state
          setLinkedClients(linkedClients.filter(id => id !== clientId));
        } catch (error) {
          console.error('Error unlinking client:', error);
        }
      }; 

  const handleLinkClient = async () => {
    if (selectedClientToLink && !linkedClients.includes(selectedClientToLink)) {
      try {
        // Update the server-side data
        await fetch(`${baseUrl}/api/auditors/${selectedOrg}/link-client`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ clientId: selectedClientToLink }),
        });
        
        // Update local state
        setLinkedClients([...linkedClients, selectedClientToLink]);
        setSelectedClientToLink(''); // Clear the dropdown after linking
      } catch (error) {
        console.error('Error linking client:', error);
      }
    }
  }; 

  const handleDeleteTeamMember = async (index) => {
    const memberToDelete = teamMembers[index];
    
    if (memberToDelete.tempId) {
      // Remove the unsaved member from the list
      const updatedMembers = teamMembers.filter((_, i) => i !== index);
      setTeamMembers(updatedMembers);
      return;
    }
    
    if (!memberToDelete.uid) return; // Safeguard if no uid is present for saved members
    
    const confirmDelete = window.confirm(`Are you sure you want to delete ${memberToDelete.email}? This action cannot be undone.`);
    
    if (confirmDelete) {
      setDeleting(true);
      try {
        const response = await fetch(`${baseUrl}/api/delete-team-member`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ orgId: selectedOrg, userId: memberToDelete.uid }),
        });
  
        if (response.ok) {
          alert('Team member deleted successfully.');
          // Remove the member from the list after successful deletion from the database
          const updatedMembers = teamMembers.filter((_, i) => i !== index);
          setTeamMembers(updatedMembers);
        } else {
          const errorData = await response.json();
          console.error('Error deleting team member:', errorData);
          alert(`Error deleting team member: ${errorData.message}`);
        }
      } catch (error) {
        console.error('Network error:', error);
        alert('Network error. Please try again later.');
      } finally {
        setDeleting(false);
      }
    }
  }; 

  const handleTeamMemberChange = (index, field, value) => {
    const updatedMembers = teamMembers.map((member, i) =>
      i === index ? { ...member, [field]: value } : member
    );
    setTeamMembers(updatedMembers);
  }; 

  const handleFrameworkChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setComplianceFramework(prevState => [...prevState, value]);
    } else {
      setComplianceFramework(prevState => prevState.filter((framework) => framework !== value));
    }
  };
  
  const handlePlatformChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setCloudPlatform(prevState => [...prevState, value]);
    } else {
      setCloudPlatform(prevState => prevState.filter((platform) => platform !== value));
    }
  }; 

  const handleSave = async () => {
    setSaving(true);
  
    const formattedTeamMembers = teamMembers.map((member) => {
      return {
        ...member,
        role: member.role.toLowerCase(),
        active: true,
        admin: member.role.toLowerCase() === 'admin',
        auditor: activeTab === 'auditors',
        client: activeTab === 'clients',
      };
    });
  
    let orgIdToSave = selectedOrg; // Initialize orgIdToSave here
  
    // Only create a new client organization if not already selected
    let orgType = ''; // Declare orgType outside the try block

if (!selectedOrg && (activeTab === 'clients' || activeTab === 'auditors')) {
  try {
    // Assign orgType based on the active tab
    orgType = activeTab === 'clients' ? 'client' : 'auditor';
    const createOrgResponse = await fetch(`${baseUrl}/api/orgs`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name: orgName, type: orgType, createdAt: new Date().toISOString() }),
    });

    if (!createOrgResponse.ok) {
      throw new Error(`Failed to create ${orgType} organization: ${createOrgResponse.statusText}`);
    }

    const newOrgData = await createOrgResponse.json();
    setSelectedOrg(newOrgData.id); // Set the newly created org as selected
    orgIdToSave = newOrgData.id; // Assign new org ID to orgIdToSave
  } catch (error) {
    console.error(`Error creating ${orgType} organization:`, error);
    alert(`Error creating ${orgType} organization. Please try again.`);
    setSaving(false);
    return;
  }
} 
  
    try {
      const response = await fetch(`${baseUrl}/api/save-provisioning`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          orgId: orgIdToSave, 
          name: orgName, 
          startDate: activeTab === 'clients' ? startDate : undefined,
          customNotes: activeTab === 'clients' || activeTab === 'auditors' ? customNotes : undefined,
          complianceFramework: activeTab === 'clients' ? complianceFramework : undefined,
          cloudPlatform: activeTab === 'clients' ? cloudPlatform : undefined,
          linkedClients: activeTab === 'auditors' ? linkedClients : undefined,
          teamMembers: formattedTeamMembers 
        })});
  
      if (!response.ok) {
        throw new Error(`Failed to save provisioning: ${response.statusText}`);
      }
  
      const result = await response.json();
      console.log('Provisioning saved successfully:', result);
      alert('Provisioning saved successfully');
  
      // Fetch and update team members with actual uids
      const orgDataResponse = await fetch(`${baseUrl}/api/orgs/${orgIdToSave}`);
      const orgData = await orgDataResponse.json();
      setTeamMembers(orgData.teamMembers || [{ fname: '', lname: '', email: '', role: 'User' }]);
  
    } catch (error) {
      console.error('Network error:', error);
      alert('Network error. Please try again later.');
    } finally {
      setSaving(false);
    }
  }; 
   
  const handlePurge = async () => {
    if (!selectedOrg) {
      alert(`Please select a ${activeTab} to purge.`);
      return;
    }

    const confirmPurge = window.confirm(`Are you sure you want to delete this ${activeTab} and all associated team members? This action cannot be undone.`);

    if (confirmPurge) {
      setPurging(true);
      try {
        const response = await fetch(`${baseUrl}/api/purge-${activeTab}/${selectedOrg}`, {
          method: 'DELETE',
        });

        if (response.ok) {
          alert(`${activeTab.charAt(0).toUpperCase() + activeTab.slice(1)} and associated team members deleted successfully.`);

          setSelectedOrg('');
          setOrgName('');
          setStartDate('');
          setCustomNotes('');
          setTeamMembers([{ fname: '', lname: '', email: '', role: 'user' }]);
          setComplianceFramework([]);
          setCloudPlatform([]);
          setLinkedClients([]); // Clear linked clients

          const updatedOrgsResponse = await fetch(`${baseUrl}/api/${activeTab}`);
          const updatedOrgs = await updatedOrgsResponse.json();
          setOrgs(updatedOrgs);
        } else {
          const errorData = await response.json();
          console.error(`Error purging ${activeTab}:`, errorData);
          alert(`Error purging ${activeTab}: ${errorData.message}`);
        }
      } catch (error) {
        console.error('Network error:', error);
        alert('Network error. Please try again later.');
      } finally {
        setPurging(false);
      }
    }
  }; 

  return (
    <div className="provisioning-container">
      <div className="tabs">
      <button
  className={`tab-button ${activeTab === 'clients' ? 'active' : ''}`}
  onClick={() => setActiveTab('clients')}
>
  Clients
</button>
        <button
          className={`tab-button ${activeTab === 'auditors' ? 'active' : ''}`}
          onClick={() => setActiveTab('auditors')}
        >
          Auditors
        </button>
        <button
          className={`tab-button ${activeTab === 'vayu' ? 'active' : ''}`}
          onClick={() => setActiveTab('vayu')}
        >
          Vayu
        </button>
      </div>
  
      {/* Moved this block above other form fields */}
  <div className="form-group">
    <label>{activeTab === 'clients' ? 'Select Client' : activeTab === 'auditors' ? 'Select Auditor' : 'Select Org'}</label>
    <select value={selectedOrg} onChange={handleOrgSelect} disabled={activeTab === 'vayu'}>
    <option value="">{`-- Create New ${activeTab === 'clients' ? 'Client' : activeTab === 'auditors' ? 'Auditor' : 'Org'} --`}</option>
       {orgs
        .filter(org => org.type === (activeTab === 'clients' ? 'client' : activeTab === 'auditors' ? 'auditor' : 'vayu'))
        .map(org => (
          <option key={org.id} value={org.id}>
            {org.name}
          </option>
        ))}
    </select>
  </div>
  
      <div className="form-group">
        <label>
          {activeTab === 'clients' ? 'Client Name' : activeTab === 'auditors' ? 'Auditor Name' : 'Org Name'}
        </label>
        <input
          type="text"
          value={orgName}
          readOnly={activeTab === 'vayu'}
          onChange={(e) => setOrgName(e.target.value)}
        />
      </div>
  
      {activeTab === 'clients' && (
        <>
          <div className="form-group">
            <label>Start Date</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
  
          
        </>
      )}
  
      {activeTab === 'auditors' && (
        <>
          <div className="form-group">
  <label>Linked Clients</label>
  <div className="linked-clients">
    {linkedClients.map((clientId, index) => {
      const client = orgs.find(org => org.id === clientId);
      return (
        <div key={index} className="linked-client-badge">
          <span>{client?.name}</span>
          <button onClick={() => handleUnlinkClient(clientId)} className="unlink-client-button">
            ✖
          </button>
        </div>
      );
    })}
  </div>
</div> 
  
          <div className="form-group">
            <label>Link a Client</label>
            <select
              value={selectedClientToLink}
              onChange={(e) => setSelectedClientToLink(e.target.value)}
            >
              <option value="">-- Select Client to Link --</option>
              {orgs
                .filter(org => org.type === 'client')
                .map(org => (
                  <option key={org.id} value={org.id}>
                    {org.name}
                  </option>
                ))}
            </select>
            <button onClick={handleLinkClient} className="link-client-button">Link Client</button>
          </div>
        </>
      )}
  
  <div className="form-group">
      <div className="provisioning-container">
      <div className="form-group">
        <label>Team Members</label>
        {teamMembers.map((member, index) => (
          <div key={index} className="team-member-row">
          <input
            type="text"
            placeholder="First Name"
            value={member.fname}
            onChange={(e) => handleTeamMemberChange(index, 'fname', e.target.value)}
          />
          <input
            type="text"
            placeholder="Last Name"
            value={member.lname}
            onChange={(e) => handleTeamMemberChange(index, 'lname', e.target.value)}
          />
          <input
            type="email"
            placeholder="Email"
            value={member.email}
            onChange={(e) => handleTeamMemberChange(index, 'email', e.target.value)}
          />
          <select
            value={member.role}
            onChange={(e) => handleTeamMemberChange(index, 'role', e.target.value)}
          >
            <option value="admin">Admin</option>
            <option value="user">User</option>
          </select>
          <span
  className="info-icon"
  onClick={() => handleInfoClick(member.tempPw, member.userSetPw)}
>
  ℹ️
</span> 
          <button
            onClick={() => handleDeleteTeamMember(index)}
            className="delete-team-member-button"
          >
            Delete
          </button>
        </div>
        ))}
        <button onClick={handleAddTeamMember} className="add-team-member-button">Add Team Member</button>
      </div>

      {teamMemberPopupVisible && (
  <div className="team-member-popup active">
    <div>{popupContent}</div>
    <button onClick={closePopup}>Close</button>
  </div>
)}

{popupVisible && (
  <div className="popup-overlay">
    <div className="popup-content">
      <h3>Edit {editingSection === 'complianceFramework' ? 'Compliance Framework' : 'Cloud Platform'}</h3>
      <ul className="list-group">
        {(editingSection === 'complianceFramework' ? frameworks : cloudPlatforms).map((item) => (
          <li key={item.id || item.cloud_platform_id} className="list-group-item">
            {item.framework_name || item.cloud_platform_name}
          </li>
        ))}
      </ul>
      <div className="form-group">
        {/*
        <label>New {editingSection === 'complianceFramework' ? 'Framework' : 'Cloud Platform'}</label> 
        */} 
        <input
          type="text"
          value={newItemName}
          onChange={(e) => setNewItemName(e.target.value)}
          placeholder={`Enter new ${editingSection === 'complianceFramework' ? 'framework' : 'cloud platform'}`}
        />
        <button
          onClick={async () => {
            if (newItemName) {
              try {
                if (editingSection === 'complianceFramework') {
                  const response = await fetch(`${baseUrl}/api/frameworks`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ framework_name: newItemName }),
                  });

                  if (!response.ok) {
                    throw new Error(`Failed to add new framework: ${response.statusText}`);
                  }

                  const addedFramework = await response.json();
                  setFrameworks([...frameworks, addedFramework]);

                } else if (editingSection === 'cloudPlatform') {
                  const response = await fetch(`${baseUrl}/api/cloud-platforms`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ cloud_platform_name: newItemName }),
                  });

                  if (!response.ok) {
                    throw new Error(`Failed to add new cloud platform: ${response.statusText}`);
                  }

                  const addedPlatform = await response.json();
                  setCloudPlatforms([...cloudPlatforms, addedPlatform]);
                }

                setNewItemName(''); // Clear input after adding

              } catch (error) {
                console.error('Error adding new item:', error);
                alert(`Error adding new ${editingSection === 'complianceFramework' ? 'framework' : 'cloud platform'}. Please try again.`);
              }
            }
          }}
        >
          Add
        </button> 
      </div>
      {/* <button onClick={handlePopupSubmit}>Save</button> */}
      <button onClick={closePopup}>Cancel</button>
    </div>
  </div>
)} 
    </div>

</div>  
  
      {(activeTab === 'clients' || activeTab === 'auditors') && (
        <div className="form-group">
          <label>Custom Notes</label>
          <textarea
            value={customNotes}
            onChange={(e) => setCustomNotes(e.target.value)}
            rows="4"
          />
        </div>
      )}
  
      {activeTab === 'clients' && (
        <>
          <div className="form-group">
  <label>
    Compliance Framework 
    <button className="edit-button" onClick={() => {
      setEditingSection('complianceFramework');
      setPopupVisible(true);
    }}>
      (edit)
    </button>
  </label>
  <div className="checkbox-group">
    {frameworks.map((framework) => (
      <label key={framework.id}>
        <input
          type="checkbox"
          value={framework.id} // Use the framework ID
          checked={complianceFramework.includes(framework.id)}
          onChange={handleFrameworkChange}
        />
        {framework.framework_name} {/* Display the framework name */}
      </label>
    ))}
  </div>
</div> 
  
<div className="form-group">
  <label>
    Cloud Platform
    <button className="edit-button" onClick={() => {
      setEditingSection('cloudPlatform');
      setPopupVisible(true);
    }}>
      (edit)
    </button>
  </label>
  <div className="checkbox-group">
    {cloudPlatforms.map((platform) => (
      <label key={platform.cloud_platform_id}>
        <input
          type="checkbox"
          value={platform.cloud_platform_id} // Use the cloud platform ID
          checked={cloudPlatform.includes(platform.cloud_platform_id)}
          onChange={handlePlatformChange}
        />
        {platform.cloud_platform_name} {/* Display the cloud platform name */}
      </label>
    ))}
  </div>
</div> 
        </>
      )}
  
      <button onClick={handleSave} className="save-button">
        {saving ? <div className="button-loader">Loading...</div> : "Save"}
      </button>
      {activeTab !== 'vayu' && (
  <button onClick={handlePurge} className="purge-button">
    {purging ? <div className="button-loader">Loading...</div> : "Purge"}
  </button>
  
)} 
    </div>
    
  ); 
};

export default Provisioning;
 