import React from 'react';
import './Loader.css';

const Loader = () => {
  return (
    <div className="cyber-loader">
      <div className="cyber-text-custom">
        Syncing your tenant's up-to-date control mappings...
      </div>
      <div className="cyber-bar-wrapper">
        <div className="cyber-bar"></div>
        <div className="cyber-bar-delay"></div>
        <div className="cyber-bar-glow"></div>
      </div>
    </div>
  );
};

export default Loader; 